import React, { useContext, useEffect, useState } from "react";
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Image,
  TextInput,
  FlatList,
  Pressable,
} from "react-native";
import { styles } from "../../theme/GlobalStyle";
import ConfigCapone from "../../utils/ConfigCapone";
import axios from "axios";
import { StackScreenProps } from "@react-navigation/stack";
import { HeaderNewRepair as Header } from "../../components/HeaderNewRepair";
import { useForm, Controller } from "react-hook-form";
import PopUpMessageWithRoute from "../../components/PopUpMessageWithRoute";
import { UserContext } from "../../context/UserContext";
import { useIsFocused } from "@react-navigation/native";
import Loading from "../../components/Loading";
import ResponseAPI from "../../Models/ResponseAPI";
import api_capone from "../../api/api_capone";
import CurrencyInput from "react-currency-input-field";
import { IReferenzia } from "../../interfaces/IReferenzia";
import { styleInputBlock } from "../../GeneralStyles/SCDue/ControlPanel";
import { useNotifyCrud } from "../../hooks/useNotifyCrud";
import Cookies from 'universal-cookie';
import { useUserPermission } from "../../hooks/useUserPermission";

export interface MatricolaModel {
  idMatricola: string;
  idReferenzia: string;
  idNegozio: string;
  matricola: string;
  putAside: boolean;
  putMoveBusiness: boolean;
  putSale: boolean;
  putSaleNewCustomer: boolean;
  putSaleExistingCustomer: boolean;
  putOnline: boolean;
  idClient: null;
  total: string;
}

interface Props extends StackScreenProps<any, any> { }

export const MagazzinoAddScreen = ({ route, navigation }: Props) => {
  let arrayData: any = route.params || [];
  const { userInLogin } = useContext(UserContext);
  const { createdPosted } = useNotifyCrud()
  const [title, setTitle] = useState("");
  const [titleType, setTitleType] = useState([]);
  const [apiBusiness, setApiBusiness] = useState([]);
  const [apiBrand, setapiBrand] = useState([]);

  const [apiFamiglia, setapiFamiglia] = useState([]);
  const [idBrandSelect, setIdBrandSelect] = useState<Number>();
  const [activeSpinner, setActiveSpinner] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const [existingReferenza, setExistingReferenza] = useState({});
  const [modaleMessaggeWithUrl, setmodaleMessaggeWithUrl] = useState({
    title: "Informazione",
    message: "",
    active: false,
    route: "",
  });

  const { isAdmin, isMarketing, isDirectore, isDipendente } = useUserPermission()

  const [changePrezzo, setChangePrezzo] = useState<boolean>(false);

  const [dataReferenzia, setDataReferenzia] = useState<IReferenzia>();
  const [searchInputReference, setSearchInputReference] = useState<string>();
  const [autosuggestInputReference, setAutosuggestInputReference] = useState<
    IReferenzia[]
  >([]);
  const [images, setImages] = useState<any>();
  const [nameImgSelected, setNameImgSelected] = useState("");
  const [isRemoved, setIsRemoved] = useState(false);
  const [changeImage, setChangeImage] = useState(false);
  const [existsImage, setExistsImage] = useState(true);
  const [fReader, setFReader] = useState(null);

  const [isFilter, setIsFilter] = useState<boolean>(false);
  const [isRedirect, setIsRedirect] = useState<boolean>(false);
  const [isModificateFamilia, setIsModificateFamilia] = useState(false);
  let isActiveFilter: boolean = false;
  const cookies = new Cookies();
  const [listOriginalMatricole, setListOriginalMatricole] = useState<any>([
    {
      idClient: null,
      idMatricola: "",
      idNegozio: "",
      idReferenzia: "",
      Matricola: "",
      putAside: false,
      putMoveBusiness: false,
      putOnline: true,
      putSale: false,
      putSaleExistingCustomer: false,
      putSaleNewCustomer: false,
      onFocus: false,
      hasError: true,
    },
  ]);

  const [optionAddEdit, setOptionAddEdit] = useState(false);
  const [optionSearch, setOptionSearch] = useState(false);
  const [editIdReference, setEditIdReference] = useState<number>();
  const [editIdMaggazino, setEditIdMaggazino] = useState<number>();

  const [editListMatricola, setEditListMatricola] = useState<any>([
    {
      idClient: null,
      idMatricola: "",
      idNegozio: "",
      idReferenzia: "",
      matricola: "",
      putAside: false,
      putMoveBusiness: false,
      putOnline: false,
      putSale: false,
      putSaleExistingCustomer: false,
      putSaleNewCustomer: false,
      total: "",
    },
  ]);

  const [stateEdit, setStateEdit] = useState(false);
  let dataEditMatricola: any = [];

  const [listMatricole, setListMatricole] = useState<any>([
    {
      idClient: null,
      idMatricola: "",
      idNegozio: "",
      idReferenzia: "",
      Matricola: "",
      putAside: false,
      putMoveBusiness: false,
      putOnline: false,
      putSale: false,
      putSaleExistingCustomer: false,
      putSaleNewCustomer: false,
      onFocus: false,
      hasError: true,
    },
  ]);

  const [matricole, setMatricole] = useState<any[]>();
  const [deleteMatricole, setDeleteMatricole] = useState<any[]>();

  const errorMessageRequired = "Il campo è obbligatorio";

  let dataMagazzino = arrayData ? arrayData.dataMagazzino : {};
  let dataMatricola = arrayData ? arrayData.dataMatricola : "";

  const [newDataMagazzino, setNewDataMagazzino] = useState<any>();

  //setNewDataMagazzino(dataMagazzino);

  const valueDefect: any = {
    Referencia: arrayData ? dataMagazzino?.referencia : "",
    Matricole: arrayData ? dataMatricola : "",
    Brand: arrayData ? dataMagazzino?.idBrand : "",
    Descrizione: arrayData ? dataMagazzino?.idFamiglia : "",
    Negozio: arrayData
      ? dataMagazzino?.idBussines == 7
        ? ""
        : dataMagazzino?.idBussines
      : "",
    Prezzo: arrayData ? dataMagazzino?.prezzo : "",
    Misuraquadrante: arrayData ? dataMagazzino?.misuraQuadrante : "",
    Colorequadrante: arrayData ? dataMagazzino?.coloreQuadrante : "",
    Tipodimovimento: arrayData ? dataMagazzino?.tipoDiMovimento : "",
  };

  const {
    setValue,
    getValues,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({

    defaultValues: valueDefect,
  });

  const handleData = async (activeNavigation: boolean = false) => {
    console.log('MagazzinoError - add-magazino')
    let checkMatricola = 0; // checkMatricole();
    let referenzaExis = autosuggestInputReference.filter((x) => x.referencia.toLowerCase() == searchInputReference.toLowerCase())
    if (searchInputReference.toLowerCase() == referenzaExis[0]?.referencia.toLowerCase()) {
      setmodaleMessaggeWithUrl({
        active: true,
        message:
          "Il riferimento esiste, deve essere selezionato.",
        title: "Errore.",
        route: "",
      });
    } else {
      if (checkMatricola == 0) {
        let verifyExisting = await checkExistingReferenza(
          getValues("Referencia"),
          (!isAdmin && !isMarketing) ? userInLogin.idBussines : Number(getValues("Negozio"))
        );
        let data = verifyExisting?.split("-");
        let Referencz = getValues("Referencia")
        let Numb = Number(getValues("Prezzo"))
        let idFami = Number(getValues("Descrizione"))
        editPrezzoReferenza(Referencz, Numb, idFami)
        if (data[0] == "yes") {
          if (
            Number(getValues("Brand")) == Number(data[4]) //&& Number(getValues("Prezzo")) == Number(data[5])
          ) {
            addMatricole(data[1], true, data[2], data[3]);
          } else {
            if (changePrezzo) {
              setmodaleMessaggeWithUrl({
                active: true,
                message:
                  "Errore, esiste già una referenza con lo stesso nome e lo stesso negozio. Cambiare Brand o prezzo",
                title: "Informazione",
                route: "",
              });
              setChangePrezzo(false)
            } else {
              setmodaleMessaggeWithUrl({
                active: true,
                message:
                  "Modifica salvata con successo.",
                title: "Informazione",
                route: "",
              });
            }

          }
        } else {
          setActiveSpinner(true);

          let nameF = getValues("Referencia") + ".png";
          let removeS = nameF.replace(/\//g, "-");
          let fileName = removeS;
          let extension = "";

          const newReferenzia = {
            idBrand: Number(getValues("Brand")),
            idBussines: (!isAdmin && !isMarketing) ? userInLogin.idBussines : Number(getValues("Negozio")),
            Referencia: getValues("Referencia"),
            Prezzo: Number(getValues("Prezzo")),
            idFamiglia: Number(getValues("Descrizione")),
            MisuraQuadrante: getValues("Misuraquadrante"),
            ColoreQuadrante: getValues("Colorequadrante"),
            TipoDiMovimento: getValues("Tipodimovimento"),
            DateInsert: new Date(),
            DateUdate: new Date(),
            FileName: null,
          };

          if (nameImgSelected.length != 0) {
            var originalFileName: string = nameImgSelected;
            extension = originalFileName.substr(
              originalFileName.lastIndexOf(".") + 1
            );

            let date = new Date().toLocaleDateString();
            date = date.replaceAll("/", "-");
            let hour = new Date().toLocaleTimeString();
            hour = hour.replaceAll(":", "-");
            date += "_" + hour;
            date = date.replace(/\s/g, "");

            // fileName = "REF_" + date + "." + extension;
            let nameFile = newReferenzia.Referencia + "." + extension;
            let removeSpecial = nameFile.replace(/\//g, "-");
            fileName = removeSpecial;
          }

          console.log(fileName );

          newReferenzia.FileName = fileName;

          await axios
            .post(`${ConfigCapone.urlapi}/api/Referenzia`, newReferenzia, {
              headers: {
                'Authorization': `Bearer ${cookies.get('token')}`
              }
            })
            .then((response) => {
              const idReferenzia = response.data.id;
              // createdPosted({
              //   actionLog:'nuovo prodotto',
              //   message:` ${userInLogin.desc_role} ha aggiunto Referenza: ${newReferenzia.Referencia} - Carica prodotto`
              // })
              addMatricole(idReferenzia);
              if (nameImgSelected.length != 0 && changeImage) {
                saveFile(fileName, extension);
              }
            })
            .catch((error) => {
              setActiveSpinner(false);
              setmodaleMessaggeWithUrl({
                active: true,
                message: `${error}`,
                title: "Errore",
                route: "",
              });
            });

          setActiveSpinner(false);
        }
      }
    }

  };

  const handleDataEdit = async (activeNavigation: boolean = false) => {
    console.log('MagazzinoError - edit-magazino')
    let checkMatricola = 0; // checkMatricole();
    if (checkMatricola == 0) {
      let verifyExisting = await checkExistingReferenza(
        getValues("Referencia"),
        Number(getValues("Negozio"))
      );
      let data = verifyExisting?.split("-");
      let Referencz = getValues("Referencia")
      let Numb = Number(getValues("Prezzo"))
      let idFami = Number(getValues("Descrizione"))
      editPrezzoReferenza(Referencz, Numb, idFami)
      setActiveSpinner(true);

      if (data[0] == "yes") {
        if (
          Number(getValues("Brand")) == Number(data[4]) //&& Number(getValues("Prezzo")) == Number(data[5])
        ) {
          //data[1] REFERENCIA, true, data[2] MAGAZZINO, data[3] CANTIDAD
          await editMatricole(data[1], true, data[2], data[3]);
        } else {
          if (changePrezzo) {
            setmodaleMessaggeWithUrl({
              active: true,
              message:
                "Errore, esiste già una referenza con lo stesso nome e lo stesso negozio. Cambiare Brand o prezzo",
              title: "Informazione",
              route: "",
            });
            setChangePrezzo(false)
          } else {
            setmodaleMessaggeWithUrl({
              active: true,
              message:
                "Modifica salvata con successo.",
              title: "Informazione",
              route: "",
            });
          }
        }
      } else {
        if (!isAdmin && !isMarketing && !dataMagazzino && optionAddEdit === true) {
          await handleData();
        } else {
          if (optionAddEdit === true) {
            let brand: any;
            let negozio: any;

            await axios
              .get(
                `${ConfigCapone.urlapi}/api/Referenzia/${editIdReference ? editIdReference : dataMagazzino?.idReference
                }`
              )
              .then((response) => {
                if (response.data.status != "empty") {
                  brand = Number(response.data.response.idBrand);
                  negozio = Number(response.data.response.idBussines);
                }
              })
              .catch((error) => {
                console.log("error");
              });

            
            let nameF = getValues("Referencia") + ".png";
            let removeS = nameF.replace(/\//g, "-");
            let fileName = removeS;
            let extension = "";

            if (
              brand == Number(getValues("Brand")) &&
              negozio == Number(getValues("Negozio"))
            ) {
              const updateReferenzia = {
                idReference: editIdReference,
                idBrand: Number(getValues("Brand")),
                idBussines: Number(getValues("Negozio")),
                idFamiglia: Number(getValues("Descrizione")),
                Referencia: getValues("Referencia"),
                Prezzo: Number(getValues("Prezzo")),
                FileName: null,
                DateUdate: new Date(),
              };

              if (changeImage) {
                if (nameImgSelected.length != 0) {
                  var originalFileName: string = nameImgSelected;
                  extension = originalFileName.substr(
                    originalFileName.lastIndexOf(".") + 1
                  );

                  let date = new Date().toLocaleDateString();
                  date = date.replaceAll("/", "-");
                  let hour = new Date().toLocaleTimeString();
                  hour = hour.replaceAll(":", "-");
                  date += "_" + hour;
                  date = date.replace(/\s/g, "");

                  //fileName = "REF_" + date + "." + extension;
                  let nameFile = updateReferenzia.Referencia + "." + extension;
                  let removeSpecial = nameFile.replace(/\//g, "-");
                  fileName = removeSpecial;
                } else {
                  if (arrayData) {
                    if (arrayData.dataMagazzino != undefined) {
                      if (isRemoved == false) {
                        fileName = arrayData.dataMagazzino.fileName;
                      }
                    }
                  }
                }
              } else {
                fileName = nameImgSelected;
                if (nameImgSelected.length == 0) {
                  let nameF = getValues("Referencia") + ".png";
                  let removeS = nameF.replace(/\//g, "-");
                  fileName = removeS;
                }
                if (arrayData) {
                  if (arrayData.dataMagazzino != undefined) {
                    if (isRemoved == false) {
                      fileName = arrayData.dataMagazzino.fileName;
                    }
                  }
                }
              }

              updateReferenzia.FileName = fileName;
              await axios
                .put(
                  `${ConfigCapone.urlapi}/api/Referenzia/${editIdReference}`,
                  updateReferenzia, {
                  headers: {
                    'Authorization': `Bearer ${cookies.get('token')}`
                  }
                }
                )
                .then((response) => {
                  editMatricole(editIdReference);
                  if (nameImgSelected.length != 0 && changeImage) {
                    saveFile(fileName, extension);
                  }
                })
                .catch((error) => {
                  setActiveSpinner(false);
                  setmodaleMessaggeWithUrl({
                    active: true,
                    message: `${error}`,
                    title: "Errore",
                    route: "",
                  });
                });

              await axios
                .get(
                  `${ConfigCapone.urlapi}/api/Matricola/FindByReference/${editIdReference}`
                )
                .then((response) => {
                  setListMatricole(response.data.response);
                })
                .catch((err) => console.log(err));
            } else {
              const newReferenzia = {
                idBrand: Number(getValues("Brand")),
                idBussines: Number(getValues("Negozio")),
                Referencia: getValues("Referencia"),
                Prezzo: Number(getValues("Prezzo")),
                idFamiglia: Number(getValues("Descrizione")) != 0 ? Number(getValues("Descrizione")) : null,
                DateInsert: new Date(),
                DateUdate: new Date(),
                FileName: null,
              };

              if (nameImgSelected.length != 0) {
                var originalFileName: string = nameImgSelected;
                extension = originalFileName.substr(
                  originalFileName.lastIndexOf(".") + 1
                );

                let date = new Date().toLocaleDateString();
                date = date.replaceAll("/", "-");
                let hour = new Date().toLocaleTimeString();
                hour = hour.replaceAll(":", "-");
                date += "_" + hour;
                date = date.replace(/\s/g, "");

                // fileName = "REF_" + date + "." + extension;
                fileName = newReferenzia.Referencia + "." + extension;
              }

              newReferenzia.FileName = fileName;

              let idReferenzia = 0;

              await axios
                .post(`${ConfigCapone.urlapi}/api/Referenzia`, newReferenzia, {
                  headers: {
                    'Authorization': `Bearer ${cookies.get('token')}`
                  }
                })
                .then((response) => {
                  idReferenzia = response.data.id;
                  addMatricole(idReferenzia);
                  if (nameImgSelected.length != 0 && changeImage) {
                    saveFile(fileName, extension);
                  }
                })
                .catch((error) => {
                  setActiveSpinner(false);
                  setmodaleMessaggeWithUrl({
                    active: true,
                    message: `${error}`,
                    title: "Errore",
                    route: "",
                  });
                });

              await axios
                .get(
                  `${ConfigCapone.urlapi}/api/Matricola/FindByReference/${idReferenzia}`
                )
                .then((response) => {
                  setListMatricole(response.data.response);
                })
                .catch((err) => console.log(err));
            }
          } else {
            let nameF = getValues("Referencia") + ".png";
            let removeS = nameF.replace(/\//g, "-");
            let fileName = removeS;
            let extension = "";

            const updateReferenzia = {
              idReference: dataMagazzino?.idReference,
              idBrand: Number(getValues("Brand")),
              idBussines: Number(getValues("Negozio")),
              idFamiglia: Number(getValues("Descrizione")) != 0 ? Number(getValues("Descrizione")) : null,
              Referencia: getValues("Referencia"),
              Prezzo: Number(getValues("Prezzo")),
              FileName: null,
              DateUdate: new Date(),
              MisuraQuadrante: getValues("Misuraquadrante"),
              ColoreQuadrante: getValues("Colorequadrante"),
              TipoDiMovimento: getValues("Tipodimovimento"),
            };

            if (changeImage) {
              if (nameImgSelected.length != 0) {
                var originalFileName: string = nameImgSelected;
                extension = originalFileName.substr(
                  originalFileName.lastIndexOf(".") + 1
                );

                let date = new Date().toLocaleDateString();
                date = date.replaceAll("/", "-");
                let hour = new Date().toLocaleTimeString();
                hour = hour.replaceAll(":", "-");
                date += "_" + hour;
                date = date.replace(/\s/g, "");

                // fileName = "REF_" + date + "." + extension;
                let nameFile = updateReferenzia.Referencia + "." + extension;
                let removeSpecial = nameFile.replace(/\//g, "-");
                fileName = removeSpecial;
              } else {
                if (arrayData) {
                  if (arrayData.dataMagazzino != undefined) {
                    if (isRemoved == false) {
                      fileName = arrayData.dataMagazzino.fileName;
                    }
                  }
                }
              }
            } else {
              fileName = nameImgSelected;
              if (nameImgSelected.length == 0) {
                let nameF = getValues("Referencia") + ".png";
                let removeS = nameF.replace(/\//g, "-");
                fileName = removeS;
              }

              if (arrayData) {
                if (arrayData.dataMagazzino != undefined) {
                  if (isRemoved == false) {
                    fileName = arrayData.dataMagazzino.fileName;
                  }
                }
              }
            }

            updateReferenzia.FileName = fileName;

            let idReferenzia = 0;

            await axios
              .put(
                `${ConfigCapone.urlapi}/api/Referenzia/${dataMagazzino?.idReference}`,
                updateReferenzia, {
                headers: {
                  'Authorization': `Bearer ${cookies.get('token')}`
                }
              }
              )
              .then((response) => {
                idReferenzia = dataMagazzino?.idReference;
                editMatricole(idReferenzia);
                if (nameImgSelected.length != 0 && changeImage) {
                  saveFile(fileName, extension);
                }
              })
              .catch((error) => {
                setActiveSpinner(false);
                setmodaleMessaggeWithUrl({
                  active: true,
                  message: `${error}`,
                  title: "Errore",
                  route: "",
                });
              });

            await axios
              .get(
                `${ConfigCapone.urlapi}/api/Matricola/FindByReference/${dataMagazzino?.idReference}`
              )
              .then((response) => {
                setListMatricole(response.data.response);
              })
              .catch((err) => console.log(err));
          }
        }
      }

      setActiveSpinner(false);

      isRedirect ? (
        setTimeout(() => {
          navigation.navigate("MagazzinoHomeScreen")
        }, 2000)
      ) : (
        setIsModificateFamilia(true)
      )


    }
  };

  const saveFile = (fileName: string, extension: string) => {
    //save File in path -> Files
    var formData = new FormData();

    let myNewFile: any = null;
    var imageFile: any = document.querySelector("#image-files-send");

    myNewFile = new File([imageFile.files[0]], fileName, {
      type: imageFile.files[0].type,
    });
    formData.append("files", myNewFile);
    formData.append("type", "image");
    formData.append("name", fileName);
    formData.append("extension", extension);
    axios
      .post(
        `${ConfigCapone.apicapone}/upload/prodotto`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => { });
  };

  const addMatricole = async (
    idReferenzia: number,
    exists?: any,
    idMagazzino?: any,
    quantity?: any
  ) => {
    const newMatricola = {
      idReferenzia: idReferenzia,
      idNegozio: Number(getValues("Negozio")),
    };

    let status: boolean = true;

    let newArr = [...listMatricole];

    let dataSend: any = [];
    //enviar Correo
    let dataSendEmail: any = [];
    if (Object.keys(listMatricole).length > 0) {

      let bussinnes = "";
      for (let index = 0; index < apiBusiness.length; index++) {
        if (apiBusiness[index].idBussines == newMatricola.idNegozio) {
          bussinnes = apiBusiness[index].desc_Bussines;
        }
      }

      for (let i = 0; i < listMatricole.length; i++) {
        if (
          listMatricole[i].Matricola != "" &&
          listMatricole[i].putOnline != false
        ) {
          dataSendEmail.push({ idReferenzia: newMatricola.idReferenzia, Referenzia: getValues("Referencia"), idNegozio: bussinnes, Matricola: listMatricole[i].Matricola, Prezzo: Number(getValues("Prezzo")), PutOnline: true })
        }
      }
    }
    if (Object.keys(dataSendEmail).length > 0) {
      let mat = [];
      for (let index = 0; index < dataSendEmail.length; index++) {
        mat.push(`<li>${dataSendEmail[index].Matricola}</li>`);
      }


      let date = dateTimeNow();
      let emailData = {
        type: "notice",
        email: `${ConfigCapone.emailMarketing}`,
        subject: "Nuovo acquisto",
        message: `<p>Operazione: Nuovo acquisto:</p>
                 <p>Operatore: ${userInLogin.userName}</p>
                 <p> Referenza: ${dataSendEmail[0].Referenzia} </p>
                 <p> Negozio: ${dataSendEmail[0].idNegozio} </p>
                 Matricola:<ul> ${mat.join(" ")}</ul> 
                 <p>  Prezzo: ${dataSendEmail[0].Prezzo}</p>
                 <p>  Data: ${date}</p>`,
      };

      await axios
        .post(`${ConfigCapone.apicapone}/api/forma`, emailData)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => console.log("there was an error: " + err));
    }


    let longitud = 0;

    if (Object.keys(listMatricole).length > 0) {
      for (let i = 0; i < listMatricole.length; i++) {
        if (listMatricole[i].Matricola != "") {
          newArr[i].idReferenzia = newMatricola.idReferenzia;
          newArr[i].idNegozio = newMatricola.idNegozio;
          dataSend.push({ idReferenzia: newMatricola.idReferenzia, idNegozio: newMatricola.idNegozio, Matricola: listMatricole[i].Matricola, PutOnline: (listMatricole[i].putOnline != false) ? true : false })
        }
      }
      if (Object.keys(dataSend).length > 0) {
        setListMatricole(newArr);

        for (let i = 0; i < dataSend.length; i++) {
          await axios
            .post(`${ConfigCapone.urlapi}/api/Matricola`, dataSend[i], {
              headers: {
                'Authorization': `Bearer ${cookies.get('token')}`
              }
            })
            .then((response) => {
              status = true;
            })
            .catch((error) => {
              status = false;
              console.log(error);
            });
        }

        longitud = dataSend.length;
      }
    }

    if (status) {
      if (exists) {
        addMagazzino(idReferenzia, longitud, true, idMagazzino, quantity);
      } else {
        addMagazzino(idReferenzia, longitud);
      }
    } else {
      setmodaleMessaggeWithUrl({
        active: true,
        message: "Si è verificato un errore durante il salvataggio 1.",
        title: "Errore",
        route: "",
      });
    }
  };

  const addMagazzino = async (
    idReferenzia: number,
    quantity: number,
    exists?: any,
    idMagazzino?: any,
    quantityMagazzino?: any
  ) => {
    if (exists) {
      const updateMagazzino = {
        idMagazzino: idMagazzino,
        Quantity: quantity + parseInt(quantityMagazzino),
        idBussines: Number(getValues("Negozio")),
      };

      await axios
        .put(
          `${ConfigCapone.urlapi}/api/Magazzino/ModifyQuantity/${updateMagazzino.idMagazzino}/${updateMagazzino.Quantity}/${updateMagazzino.idBussines}`, {}, {
          headers: {
            'Authorization': `Bearer ${cookies.get('token')}`
          }
        }
        )
        .then((response) => {
          setmodaleMessaggeWithUrl({
            active: true,
            message: "Salvato con successo.",
            title: "Informazione",
            route: "",
          });
          setEditIdReference(idReferenzia);
          setEditIdMaggazino(parseInt(response.data.id));
          setOptionAddEdit(true);

          if (stateEdit === false) {
            setStateEdit(true);
          } else {
            setStateEdit(false);
          }
        })
        .catch((error) => {
          setActiveSpinner(false);
          setmodaleMessaggeWithUrl({
            active: true,
            message: "Si è verificato un errore durante il salvataggio.",
            title: "Errore",
            route: "",
          });
        });

      await axios
        .get(
          `${ConfigCapone.urlapi}/api/Referenzia/FindByIdReferenciaMagazzino/${idReferenzia}`
        )
        .then((response) => {
          resultSearch(response.data.response);
        })
        .catch((err) => console.log(err));
    } else {
      const newMagazzino = {
        idUser: userInLogin.idUser,
        idBussines: Number(getValues("Negozio")),
        idReferenzia: idReferenzia,
        Quantity: quantity,
      };

      await axios
        .post(`${ConfigCapone.urlapi}/api/Magazzino`, newMagazzino, {
          headers: {
            'Authorization': `Bearer ${cookies.get('token')}`
          }
        })
        .then((response) => {
          setmodaleMessaggeWithUrl({
            active: true,
            message: "Salvato con successo.",
            title: "Informazione",
            route: "",
          });

          setEditIdReference(idReferenzia);
          setEditIdMaggazino(parseInt(response.data.id));
          setOptionAddEdit(true);

          if (stateEdit === false) {
            setStateEdit(true);
          } else {
            setStateEdit(false);
          }
        })
        .catch((error) => {
          setActiveSpinner(false);
          setmodaleMessaggeWithUrl({
            active: true,
            message: "Si è verificato un errore durante il salvataggio.",
            title: "Errore",
            route: "",
          });
        });




      await axios
        .get(
          `${ConfigCapone.urlapi}/api/Referenzia/FindByIdReferenciaMagazzino/${idReferenzia}`
        )
        .then((response) => {
          resultSearch(response.data.response);
        })
        .catch((err) => console.log(err));
    }

    setTimeout(() => {
      navigation.navigate("MagazzinoHomeScreen")
    }, 3000);

    // reset({})
  };
  const emailSendMatricole = async (
    user?: any,
    sendE?: any
  ) => {
    let dataSendEmail: any = [];

    if (Object.keys(sendE).length > 0) {
      let bussinnes = "";
      for (let index = 0; index < apiBusiness.length; index++) {
        if (apiBusiness[index].idBussines == sendE[0].idNegozio) {
          bussinnes = apiBusiness[index].desc_Bussines;
        }
      }
      for (let i = 0; i < sendE.length; i++) {
        if (
          sendE[i].PutOnline != false
        ) {
          dataSendEmail.push({ Referenzia: getValues("Referencia"), idNegozio: bussinnes, Matricola: sendE[i].Matricola, Prezzo: Number(getValues("Prezzo")), PutOnline: true })
        }
      }
    }

    if (Object.keys(dataSendEmail).length > 0) {
      let mat = [];
      for (let index = 0; index < dataSendEmail.length; index++) {
        mat.push(`<li>${dataSendEmail[index].Matricola}</li>`);
      }

      let date = dateTimeNow();
      let emailData = {
        type: "notice",
        email: `${ConfigCapone.emailMarketing}`,
        subject: "Nuovo acquisto",
        message: `<p>Operazione: Nuovo acquisto:</p>
                 <p>Operatore: ${userInLogin.userName}</p>
                 <p> Referenza: ${dataSendEmail[0].Referenzia} </p>
                 <p> Negozio: ${dataSendEmail[0].idNegozio} </p>
                 Matricola:<ul> ${mat.join(" ")}</ul> 
                 <p>  Prezzo: ${dataSendEmail[0].Prezzo}</p>
                 <p>  Data: ${date}</p>`,
      };

      await axios
        .post(`${ConfigCapone.apicapone}/api/forma`, emailData)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => console.log("there was an error: " + err));
    }
  }
  const editMatricole = async (
    idReferenzia: number,
    exists?: any,
    idMagazzino?: any,
    quantity?: any
  ) => {
    const newMatricola = {
      idReferenzia: idReferenzia,
      idNegozio: Number(getValues("Negozio")),
    };

    let status: boolean = false;

    let longitud: number;

    let newArr = [...listMatricole];

    let dataSend: any = [];
    if (Object.keys(listMatricole).length > 0) {
      for (let i = 0; i < listMatricole.length; i++) {
        newArr[i].idReferenzia = newMatricola.idReferenzia;
        newArr[i].idNegozio = newMatricola.idNegozio;
        if (newArr[i].idMatricola === "") {
          if (listMatricole[i].Matricola != "") {
            dataSend.push({
              idMatricola: "no",
              idReferenzia: newMatricola.idReferenzia,
              idNegozio: newMatricola.idNegozio,
              Matricola: listMatricole[i].Matricola,
              PutOnline: listMatricole[i].putOnline == listMatricole[i].Matricola ? true : listMatricole[i].putOnline,
            });
          }
        } else {
          dataSend.push({
            idMatricola: listMatricole[i].idMatricola,
            idReferenzia: newMatricola.idReferenzia,
            idNegozio: newMatricola.idNegozio,
            Matricola: listMatricole[i].Matricola,
            putAside: listMatricole[i].putAside,
            putMoveBusiness: listMatricole[i].putMoveBusiness,
            PutOnline: listMatricole[i].putOnline == listMatricole[i].Matricola ? true : listMatricole[i].putOnline,
            putSale: listMatricole[i].putSale,
            putSaleExistingCustomer: listMatricole[i].putSaleExistingCustomer,
            putSaleNewCustomer: listMatricole[i].putSaleNewCustomer,
          });
        }
      }

      // setListMatricole(newArr);
    }
    let dataUpdate: any = [];
    let dataPost: any = [];
    let dataPostUpdate: any = [];
    let dataDelete: any = [];
    let dataDeleteData: any = [];

    dataUpdate = dataSend.filter((data: any) => data.idMatricola != "no");
    dataPostUpdate = dataSend.filter((data: any) => data.idMatricola == "no");
    dataPost = listMatricole;

    if (optionAddEdit === true) {
      if (editListMatricola?.length > 0) {
        if (editListMatricola?.length != dataUpdate?.length) {
          editListMatricola.forEach((element: any) => {
            dataDelete.push({ idMatricola: element.idMatricola });
          });

          dataUpdate.forEach((element: any) => {
            dataDeleteData.push({ idMatricola: element.idMatricola });
          });

          const resultDelete = dataDelete.filter(
            (item: any) =>
              !dataDeleteData.some(
                (dataDeleteData: any) =>
                  dataDeleteData.idMatricola === item.idMatricola
              )
          );

          // for(let i=0; i<resultDelete.length; i++) {
          //     if(resultDelete[i].idMatricola != ''){
          //         await axios
          //         .delete(`${ConfigCapone.urlapi}/api/Matricola/${resultDelete[i].idMatricola}`)
          //         .then((response) => {
          //             status = true;
          //         })
          //         .catch((error) => {
          //             status = false;
          //         });
          //     }
          // };
        }
      }
    }

    if (route.params != undefined) {
      if (dataMatricola?.length > 0) {
        if (dataMatricola?.length != dataUpdate?.length) {
          dataMatricola.forEach((element: any) => {
            dataDelete.push({ idMatricola: element.idMatricola });
          });

          dataUpdate.forEach((element: any) => {
            dataDeleteData.push({ idMatricola: element.idMatricola });
          });

          const resultDelete = dataDelete.filter(
            (item: any) =>
              !dataDeleteData.some(
                (dataDeleteData: any) =>
                  dataDeleteData.idMatricola === item.idMatricola
              )
          );

          for (let i = 0; i < resultDelete.length; i++) {
            if (resultDelete[i].idMatricola != "") {
              await axios
                .delete(
                  `${ConfigCapone.urlapi}/api/Matricola/${resultDelete[i].idMatricola}`, {
                  headers: {
                    'Authorization': `Bearer ${cookies.get('token')}`
                  }
                }
                )
                .then((response) => {
                  status = true;
                })
                .catch((error) => {
                  status = false;
                });
            }
          }




        }
      }
    }

    if (dataUpdate?.length > 0 && !optionAddEdit) {
      for (let i = 0; i < dataUpdate.length; i++) {
        await axios
          .put(
            `${ConfigCapone.urlapi}/api/Matricola/${dataUpdate[i].idMatricola}`,
            dataUpdate[i]
          )
          .then((response) => {
            status = true;
          })
          .catch((error) => {
            status = false;
          });
      }
    }

    let countSaveMatricole = 0;

    if (dataPost?.length > 0 && optionAddEdit) {

      let arraySend: any = [];

      for (let i = 0; i < dataPostUpdate.length; i++) {
        if (dataPostUpdate[i].Matricola != "") {
          arraySend.push({
            idReferenzia: newMatricola.idReferenzia,
            idNegozio: newMatricola.idNegozio,
            Matricola: dataPostUpdate[i].Matricola,
            PutOnline: dataPostUpdate[i].PutOnline,
          });
        }
      }

      for (let i = 0; i < arraySend.length; i++) {
        await axios
          .post(`${ConfigCapone.urlapi}/api/Matricola`, arraySend[i])
          .then((response) => {
            countSaveMatricole++;
            status = true;
          })
          .catch((error) => {
            status = false;
          });
      }
      emailSendMatricole(userInLogin.userName, arraySend)
    }

    if (dataPostUpdate?.length > 0 && !optionAddEdit) {
      let arraySend: any = [];

      for (let i = 0; i < dataPostUpdate.length; i++) {
        if (dataPostUpdate[i].Matricola != "") {
          arraySend.push({
            idReferenzia: newMatricola.idReferenzia,
            idNegozio: newMatricola.idNegozio,
            Matricola: dataPostUpdate[i].Matricola,
            PutOnline: dataPostUpdate[i].PutOnline,
          });
        }
      }

      for (let i = 0; i < arraySend.length; i++) {
        await axios
          .post(`${ConfigCapone.urlapi}/api/Matricola`, arraySend[i])
          .then((response) => {
            status = true;
          })
          .catch((error) => {
            status = false;
          });
      }
      emailSendMatricole(userInLogin.userName, arraySend)
    }

    if (!optionAddEdit) {
      longitud = dataSend.length;
    } else {
      longitud = countSaveMatricole;
      // longitud = countSaveMatricole;
    }

    if (route.params) {
      longitud = listMatricole.length;
      if (quantity > 0) {
        editMagazzino(idReferenzia, longitud, dataMagazzino?.idMagazzino, quantity, false, true);
      } else {
        editMagazzino(idReferenzia, longitud, dataMagazzino?.idMagazzino);
      }

    } else {
      if (status) {
        if (exists) {
          editMagazzino(idReferenzia, longitud, idMagazzino, quantity, true);
        } else {
          optionAddEdit === true
            ? editMagazzino(
              idReferenzia,
              longitud,
              editIdMaggazino,
              0,
              false,
              true
            )
            : editMagazzino(idReferenzia, longitud, dataMagazzino?.idMagazzino);
        }
      } else {
        setmodaleMessaggeWithUrl({
          active: true,
          message: "Modifica salvata con successo.",
          title: "Informazione",
          route: "",
        });
      }
    }
  };
  //CAMBIO DE PRECIO EN MISMAS REFERENCIAS
  const editPrezzoReferenza = async (
    Referenzia: any,
    prezzo?: any,
    idFamiglia?: any
  ) => {
    setChangePrezzo(true)
    let refFilter = []
    let refFilterAll = []
    await axios
      .get(`${ConfigCapone.urlapi}/api/Referenzia?parameter=1`)
      .then((response) => {
        refFilterAll = response.data.response;
      })
      .catch((err) => console.log(err));

    refFilter = refFilterAll.filter((data: any) => data.referencia == Referenzia);

    if (refFilter.length > 0) {
      for (let index = 0; index < refFilter.length; index++) {
        //update.push(idReference: refFilter[index].idReference)
        await axios
          .put(
            `${ConfigCapone.urlapi}/api/Referenzia/ModifyQuantityReferencia/${refFilter[index].idReference}/${prezzo}/${idFamiglia}`
          )
          .then((response) => {
            console.log(response)
          })
          .catch((error) => {
            setActiveSpinner(false);
            setmodaleMessaggeWithUrl({
              active: true,
              message: `${error}`,
              title: "Errore",
              route: "",
            });
          });

      }
    }
  };
  //FIN 
  const editMagazzino = async (
    idReferenzia: number,
    quantity: number,
    idMagazzino?: any,
    quantityMagazzino?: any,
    exists?: any,
    countMagazzinoUpdate?: any
  ) => {
    if (exists && exists == true) {

      let id: any;
      let status: any;
      let idBussinesUp: any;
      let quantityUp: any;

      optionAddEdit === true
        ? (id = editIdMaggazino)
        : (id = dataMagazzino?.idMagazzino);

      await axios
        .get(`${ConfigCapone.urlapi}/api/Magazzino/${id}`, {
          headers: {
            'Authorization': `Bearer ${cookies.get('token')}`
          }
        })
        .then((response) => {
          if (response.data.status != "empty") {
            status = "yes";
            idBussinesUp = response.data.response.idBussines;
            quantityUp = response.data.response.quantity;
          }
        })
        .catch((error) => {
          console.log('MagazzinoError ', error);
        });

      const updateOwnMagazzino = {
        idMagazzino: id,
        Quantity: quantityUp,
        idBussines: Number(idBussinesUp),
      };

      await axios
        .put(
          `${ConfigCapone.urlapi}/api/Magazzino/ModifyQuantity/${updateOwnMagazzino.idMagazzino}/${updateOwnMagazzino.Quantity}/${updateOwnMagazzino.idBussines}`, {
          headers: {
            'Authorization': `Bearer ${cookies.get('token')}`
          }
        }
        )
        .then((response) => {
          setmodaleMessaggeWithUrl({
            active: true,
            message: "Modifica salvata con successo.",
            title: "Informazione",
            route: "",
          });

          if (stateEdit === false) {
            setStateEdit(true);
          }
        })
        .catch((error) => {
          setActiveSpinner(false);
          setmodaleMessaggeWithUrl({
            active: true,
            message: error,
            title: "Errore",
            route: "",
          });
        });

      //
      const updateMagazzino = {
        idMagazzino: idMagazzino,
        Quantity: quantity + Number(quantityMagazzino),
        idBussines: Number(getValues("Negozio")),
      };

      await axios
        .put(
          `${ConfigCapone.urlapi}/api/Magazzino/ModifyQuantity/${updateMagazzino.idMagazzino}/${updateMagazzino.Quantity}/${updateMagazzino.idBussines}`, {
          headers: {
            'Authorization': `Bearer ${cookies.get('token')}`
          }
        }
        )
        .then((response) => {
          setmodaleMessaggeWithUrl({
            active: true,
            message: "Modifica salvata con successo.",
            title: "Informazione",
            route: "",
          });

          if (stateEdit === false) {
            setStateEdit(true);
          }
        })
        .catch((error) => {
          setActiveSpinner(false);
          setmodaleMessaggeWithUrl({
            active: true,
            message: error,
            title: "Errore",
            route: "",
          });
        });

      // update list

      await axios
        .get(
          `${ConfigCapone.urlapi}/api/Referenzia/FindByIdReferenciaMagazzino/${idReferenzia}`
        )
        .then((response) => {
          resultSearch(response.data.response);
        })
        .catch((err) => console.log(err));
    } else {
      let quantityMagazzino: any;
      let countMagazzino: number = 0;

      if (countMagazzinoUpdate) {
        if (Object.keys(listMatricole).length > 0) {
          for (let i = 0; i < listMatricole.length; i++) {
            if (listMatricole[i].Matricola.length > 0) {
              countMagazzino++;
            }
          }
        }

        if (countMagazzino > 0) {
          await axios
            .get(`${ConfigCapone.urlapi}/api/Magazzino/${idMagazzino}`, {
              headers: {
                'Authorization': `Bearer ${cookies.get('token')}`
              }
            })
            .then((response) => {
              if (response.data.status != "empty") {
                quantityMagazzino = Number(response.data.response.quantity);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }

      let sumQuantity: any;

      let updateQuantity: boolean = false;

      if (countMagazzino > 0) {
        sumQuantity = Number(quantity) + Number(quantityMagazzino)
      } else {
        sumQuantity = quantity
      }

      const updateMagazzino = {
        idMagazzino: idMagazzino,
        Quantity: sumQuantity,
        idBussines: Number(getValues("Negozio")),
      };

      await axios
        .put(
          `${ConfigCapone.urlapi}/api/Magazzino/ModifyQuantity/${updateMagazzino.idMagazzino}/${updateMagazzino.Quantity}/${updateMagazzino.idBussines}`, {
          headers: {
            'Authorization': `Bearer ${cookies.get('token')}`
          }
        }
        )
        .then((response) => {
          if (countMagazzinoUpdate) {
            axios
              .delete(
                `${ConfigCapone.urlapi}/api/Magazzino/${updateMagazzino.idMagazzino}`, {
                headers: {
                  'Authorization': `Bearer ${cookies.get('token')}`
                }
              }
              )
              .then((response) => {
                updateQuantity = true
              })
              .catch((error) => {
              });
          }
          setmodaleMessaggeWithUrl({
            active: true,
            message: "Modifica salvata con successo.",
            title: "Informazione",
            route: "",
          });

          if (stateEdit === false) {
            setStateEdit(true);
          }
        })
        .catch((error) => {
          setActiveSpinner(false);
          setmodaleMessaggeWithUrl({
            active: true,
            message: "Si è verificato un errore durante il salvataggio.",
            title: "Errore",
            route: "",
          });
        });


      // update list
      if (countMagazzinoUpdate) {
        updateQuantity = true
      }

      await axios
        .get(
          `${ConfigCapone.urlapi}/api/Referenzia/FindByIdReferenciaMagazzino/${idReferenzia}`
        )
        .then((response) => {
          if (updateQuantity) {
            resultSearch(response.data.response, false, true);
          } else {
            resultSearch(response.data.response);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const confirmAction = (Landing: any, data: any) => {
    navigation.navigate(Landing, data);
    setmodaleMessaggeWithUrl({
      title: "Informazione",
      message: "",
      active: false,
      route: "",
    });
  };

  const getTitle = async () => {
    await axios(`${ConfigCapone.urlapi}/api/Title?parameter=1`)
      .then((response) => setTitleType(response.data.response))
      .catch((error) => console.log(error));
  };

  const selectBussiness = async () => {
    setActiveSpinner(true);
    await axios
      .get(`${ConfigCapone.urlapi}/api/Bussines?parameter=1`)
      .then((response) => {
        let data = response.data.response?.filter( x => x.idBussines != 9);
        setApiBusiness(data);
      })
      .catch((err) => console.log(err));
    setActiveSpinner(false);
  };

  const selectBrand = async () => {
    setActiveSpinner(true);

    let brand: any = [];
    let brandFilter: any = [];
    await axios
      .get(`${ConfigCapone.urlapi}/api/Brand?parameter=1`)
      .then((response) => {
        brand = response.data.response;
      })
      .catch((err) => console.log(err));
    brandFilter = brand.filter((data: any) => data.desc_Brand != "Rolex");
    userInLogin.idRole == '9' ? setapiBrand(brandFilter) : setapiBrand(brand);
    setActiveSpinner(false);
  };

  const selectFamiglia = async (idbrand) => {
    setActiveSpinner(true);

    let famiglia: any = [];
    let famigliaFilter: any = [];
    await axios
      .get(`${ConfigCapone.urlapi}/api/FamigliaBrand?parameter=1`)
      .then((response) => {
        famiglia = response.data.response;
      })
      .catch((err) => console.log(err));

    famigliaFilter = famiglia.filter((data: any) => data.idBrand == idbrand);
    setapiFamiglia(famigliaFilter);

    setActiveSpinner(false);
  };

  const addEnrollment = () => {
    setListMatricole([
      ...listMatricole,
      {
        idClient: "",
        idMatricola: "",
        idNegozio: "",
        idReferenzia: "",
        Matricola: "",
        putAside: false,
        putMoveBusiness: false,
        putOnline: false,
        putSale: false,
        putSaleExistingCustomer: false,
        putSaleNewCustomer: false,
        onFocus: true,
        hasError: true,
      },
    ]);
  };

  let newDeleteMatricole = [];
  const removeMatricole = (position: number) => {
    let currentMatricole = [...listMatricole];
    let newMatricole = [];

    currentMatricole.filter((element, index) => {
      if (index != position) {
        newMatricole.push(element);
      } else {
        newDeleteMatricole.push(element);
      }
    });

    setDeleteMatricole(newDeleteMatricole);
    setListMatricole([]);
    setListMatricole(newMatricole);
  };

  const changeMatricola = (
    dataValue: any,
    Matricola: string,
    index: number,
    op?: any
  ) => {

    const value = op.type === "checkbox" ? op.checked : op.value;
    let newArr = [...listMatricole];
    newArr[index] = {
      ...newArr[index],
      [Matricola]: dataValue,
      ["putOnline"]: value,
      ["onFocus"]: true,
      ["hasError"]: dataValue.length == 0 ? true : false,
    };
    setListMatricole(newArr);
  };

  // const checkMatricole = () => {
  //     let count = 0;
  //     listMatricole.map((el:any) => {
  //         el.onFocus = true
  //         if(el.hasError) {
  //             count++
  //         }
  //     })
  //     return count;
  // }

  // const activeFocus = () => {
  //     listMatricole.map((el:any) => {
  //         el.onFocus = true
  //     })
  // }

  const checkExistingReferenza: any = async (
    referencia: string,
    idNegozio: number
  ) => {
    let state = "no";
    let findByReferenciaCreate = false;
    let idReference = "";
    let idMagazzino = "";
    let quantity = "";
    let brand = "";
    let prezzo = "";
    let magazzinoExist = dataMagazzino;

    if (!dataMagazzino) { dataMagazzino = newDataMagazzino }

    // ((dataMagazzino?.idReference || optionAddEdit === true) && (isAdmin || isMarketing))
    ((dataMagazzino?.idReference || optionAddEdit === true))
      ? await axios
        // .get(
        //   `${ConfigCapone.urlapi
        //   }/api/Referenzia/FindByReferencia/${referencia}/${optionAddEdit === true
        //     ? editIdReference
        //     : dataMagazzino?.idReference
        //   }/${(!isAdmin && !isMarketing) ? userInLogin.idBussines : idNegozio}`
        // )
        .get(
          `${ConfigCapone.urlapi}/api/Referenzia/FindByReferencia`,{
            params: {
              name: referencia,
              id: optionAddEdit === true ? editIdReference : dataMagazzino?.idReference,
              idNegozio: (!isAdmin && !isMarketing) ? userInLogin.idBussines : idNegozio
            }
          }
        )
        .then((response) => {
          if (response.data.status != "empty") {
            setExistingReferenza(response.data.response);
            state = "yes";
            idReference = response.data.response.idReference;
            idMagazzino = response.data.response.idMagazzino;
            quantity = response.data.response.quantity;
            brand = response.data.response.idBrand;
            prezzo = response.data.response.prezzo;
          } else {
            state = "no";
            findByReferenciaCreate = true
          }
        })
        .catch((error) => {
          console.log(error);
        })
      : await axios
        // .get(
        //   `${ConfigCapone.urlapi}/api/Referenzia/FindByReferenciaCreate/${referencia}/${idNegozio}`
        // )
        .get(
          `${ConfigCapone.urlapi}/api/Referenzia/FindByReferenciaCreate`,{
            params: {
              name: referencia,
              idNegozio: idNegozio
            }
          }
        )
        .then((response) => {
          if (response.data.status != "empty") {
            if (dataMagazzino) {
              let newData = [{ ...response.data.response }];
              let data = newData.find(x => Number(x.idReference) === Number(dataMagazzino?.idReference))

              let result = data ? true : false;

              if (result) {
                if (Object.keys(data).length < 0) {
                  state = "no";
                } else {
                  if (magazzinoExist) {
                    state = "no";
                  } else {
                    setExistingReferenza(response.data.response);
                    state = "yes";
                    idReference = response.data.response.idReference;
                    idMagazzino = response.data.response.idMagazzino;
                    quantity = response.data.response.quantity;
                    brand = response.data.response.idBrand;
                    prezzo = response.data.response.prezzo;
                  }

                }
              } else {
                state = "no"
              }

            } else {
              state = "no";
            }
          } else {
            state = "no";
          }
        })
        .catch((error) => {
          console.log("error", error);
        });


    if (findByReferenciaCreate && optionSearch) {
      await axios
        // .get(
        //   `${ConfigCapone.urlapi}/api/Referenzia/FindByReferenciaCreate/${referencia}/${idNegozio}`
        // )
        .get(
          `${ConfigCapone.urlapi}/api/Referenzia/FindByReferenciaCreate`,{
            params: {
              name: referencia,
              idNegozio: idNegozio
            }
          }
        )
        .then((response) => {
          if (response.data.status != "empty") {
            if (dataMagazzino) {
              let newData = [{ ...response.data.response }];
              let data = newData.find(x => x.idReference === dataMagazzino?.idReference)

              if (Object.keys(data).length < 0) {
                state = "no";
              } else {
                setExistingReferenza(response.data.response);
                state = "yes";
                idReference = response.data.response.idReference;
                idMagazzino = response.data.response.idMagazzino;
                quantity = response.data.response.quantity;
                brand = response.data.response.idBrand;
                prezzo = response.data.response.prezzo;
              }
            } else {
              state = "no";
            }
          } else {
            state = "no";
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }

    let data =
      state +
      "-" +
      idReference +
      "-" +
      idMagazzino +
      "-" +
      quantity +
      "-" +
      brand +
      "-" +
      prezzo;

    return data;
  };



  const getEditDataMatricola = async () => {
    // const objreq = new api_capone<ResponseAPI<MatricolaModel[]>>();
    // var respuesta = await objreq.GetHttpAPI(
    //     `value=${id}&parameter=idReferenzia`,
    //     "/api/Matricola?"
    //   );

    await axios
      .get(
        `${ConfigCapone.urlapi}/api/Matricola?value=${editIdReference}&parameter=idReferenzia`
      )
      .then((response) => {
        setEditListMatricola(response.data.response);
        dataEditMatricola = response.data.response;
      })
      .catch((err) => console.log(err));
  };

  const onChangeTextRefenence = async (text: string) => {

    setSearchInputReference(text);
    if (text.length > 0) {
      //setActiveSpinner(true);

      await axios
        .get(`${ConfigCapone.urlapi}/api/Referenzia/FindByLimit`, {
          params: {
            name: text,
            lasted: 5
          }
        })
        .then
        ((response) => {
          setAutosuggestInputReference(response.data.response);
        })
        .catch((err) => console.log(err));
      //setActiveSpinner(false);
    }
  };

  const getItemText = (item: IReferenzia) => {
    let mainText = item.referencia;
    return (
      <div>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <View style={{ marginLeft: 10, flexShrink: 1 }}>
            <Text
              style={{ fontWeight: "500", paddingTop: 5, paddingBottom: 5 }}
            >
              {mainText}
            </Text>
          </View>
        </View>
      </div>
    );
  };

  const resultSearch = async (data: IReferenzia, redirect?: boolean, update?: boolean) => {
    setDataReferenzia(data);
    setChangeImage(false);
    let imageContainer: any = document.getElementById("img-container-2");
    if (imageContainer != null) {
      imageContainer.classList.add("d-none");
    }

    setValue("Referencia", data?.referencia ? data?.referencia : "");

    dataMagazzino = data;
    setNewDataMagazzino(data)

    let listOriginalMatricole: any = [];
    let listConvertMatricole: any = [];

    await axios
      .get(
        `${ConfigCapone.urlapi}/api/Matricola/FindByReference/${data.idReference}`
      )
      .then((response) => {
        listOriginalMatricole = response.data.response;
      })
      .catch((err) => console.log(err));

    for (let i = 0; i < listOriginalMatricole.length; i++) {
      listConvertMatricole.push({
        idClient: listOriginalMatricole[i].idClient,
        idMatricola: listOriginalMatricole[i].idMatricola,
        idNegozio: listOriginalMatricole[i].idMatricola,
        idReferenzia: listOriginalMatricole[i].idReferenzia,
        Matricola: listOriginalMatricole[i].matricola,
        putAside: listOriginalMatricole[i].putAside,
        putMoveBusiness: listOriginalMatricole[i].putMoveBusiness,
        putOnline: listOriginalMatricole[i].putOnline,
        putSale: listOriginalMatricole[i].putSale,
        putSaleExistingCustomer:
          listOriginalMatricole[i].putSaleExistingCustomer,
        putSaleNewCustomer: listOriginalMatricole[i].putSaleNewCustomer,
        onFocus: false,
        hasError: false,
      });
    }

    setListOriginalMatricole([]);
    setListOriginalMatricole(listConvertMatricole);

    if (update) {
      await axios
        .put(
          `${ConfigCapone.urlapi}/api/Magazzino/ModifyQuantityByReference/${data.idReference}/${listConvertMatricole?.length}`, {}, {
          headers: {
            'Authorization': `Bearer ${cookies.get('token')}`
          }
        }
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (isActiveFilter) {
      setListMatricole([
        {
          idClient: null,
          idMatricola: "",
          idNegozio: "",
          idReferenzia: "",
          Matricola: "",
          putAside: false,
          putMoveBusiness: false,
          putOnline: false,
          putSale: false,
          putSaleExistingCustomer: false,
          putSaleNewCustomer: false,
          onFocus: false,
          hasError: true,
        },
      ]);
      isActiveFilter = false;
      // !isAdmin && !isMarketing && setValue("Negozio", data?.idBussines);
    } else {
      if (route.params == undefined) {
        setListMatricole([
          {
            idClient: null,
            idMatricola: "",
            idNegozio: "",
            idReferenzia: "",
            Matricola: "",
            putAside: false,
            putMoveBusiness: false,
            putOnline: false,
            putSale: false,
            putSaleExistingCustomer: false,
            putSaleNewCustomer: false,
            onFocus: false,
            hasError: true,
          },
        ]);
        isActiveFilter = false;

        if (!isAdmin && !isMarketing) {
          setValue("Negozio", data?.idBussines);
        } else {
          setValue("Negozio", "");
        }
      } else {
        setListMatricole(listConvertMatricole);
      }
    }

    setValue("Brand", data?.idBrand);

    setValue("Descrizione", data?.idFamiglia);

    setIdBrandSelect(Number(data?.idBrand));

    setValue("Prezzo", data?.prezzo);

    setValue("Misuraquadrante", data?.misuraQuadrante);

    setValue("Colorequadrante", data?.coloreQuadrante);

    setValue("Tipodimovimento", data?.tipoDiMovimento);


    if (data.fileName != null) {
      setNameImgSelected(data.fileName);

      var url = `${ConfigCapone.apicapone}/image/prodotto/${data.fileName}`;
      var http = new XMLHttpRequest();
      http.open("HEAD", url, false);
      http.send();
      if (http.status != 404) {
        console.log("image exists");
        setImgUrl(url);
      } else {
        console.log("image no exists");
        setImgUrl("");
      }
    } else {
      setNameImgSelected("");
    }

    if (data.fileName != null) {
      let imageContainer: any = document.getElementById("img-container-send");
      imageContainer.classList.remove("d-none");
    }

    setEditIdReference(parseInt(data?.idReference));
    setEditIdMaggazino(parseInt(data?.idMagazzino));
    setOptionAddEdit(true);
    setOptionSearch(true);

    (redirect && redirect == true) && setIsRedirect(true)

  };

  const uploadFiles = (e: any) => {
    setImages(e);
    setChangeImage(true);

    let imageContainer: any = document.getElementById("img-container-2");
    if (imageContainer != null) {
      imageContainer.classList.add("d-none");
    }

    if (e[0] != undefined) {
      setNameImgSelected(e ? e[0].name : null);

      let image: any = document.querySelector("#image-files-send");
      let imageContainer: any = document.getElementById("img-container-send");
      imageContainer.classList.remove("d-none");
      imageContainer.src = URL.createObjectURL(image.files[0]);
      setImgUrl(URL.createObjectURL(image.files[0]));

      let _fReader = new FileReader();
      _fReader.readAsDataURL(e[0]);
      setFReader(_fReader);
    } else {
      setNameImgSelected("");
    }
  };

  const removeFileData = () => {
    setNameImgSelected("");
    var imageFile: any = document.getElementById("image-files-send");
    imageFile.value = null;
    let imageContainer: any = document.getElementById("img-container-2");
    imageContainer.classList.add("d-none");
    setIsRemoved(true);
  };

  const removeFile = () => {
    setNameImgSelected("");
    var imageFile: any = document.getElementById("image-files-send");
    imageFile.value = null;
    let imageContainer: any = document.getElementById("img-container-send");
    imageContainer.classList.add("d-none");
    setIsRemoved(true);
  };

  useEffect(() => {
    selectFamiglia(idBrandSelect)
  }, [idBrandSelect])

  useEffect(() => {
    if (editIdReference) {
      getEditDataMatricola();
    }

    if (arrayData.dataMagazzino) {
      console.log('aquii');
      if (nameImgSelected.length == 0) {
        if (arrayData.dataMagazzino.fileName != null) {
          var url = `${ConfigCapone.apicapone}/image/prodotto/${arrayData.dataMagazzino.fileName}`;

          var http = new XMLHttpRequest();
          http.open("HEAD", url, false);
          http.send();
          if (http.status != 404) {
            console.log("image exists");
            setImgUrl(url);
          } else {
            console.log("image no exists");
            setImgUrl("");
          }
        }
      }
    }
  }, [stateEdit]);

  const noEdit = () => {
    isAdmin === false &&
      isMarketing === false &&
      !dataMagazzino &&
      setValue("Negozio", userInLogin.idBussines);
  };

  const dateTimeNow = (fecha: string = '') => {
    let date
    if (fecha !== '') {
      date = new Date(fecha)
    } else {
      date = new Date()
    }
    const year = date.getFullYear()
    const month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : date.getMonth() + 1
    const day = date.getDate().toString().padStart(2, "0")
    const hour = date.getHours().toString().padStart(2, "0")
    const minute = date.getMinutes().toString().padStart(2, "0")
    const seconds = date.getSeconds().toString().padStart(2, "0")
    const dateNow =
      day +
      '/' +
      month +
      '/' +
      year +
      ', ore ' +
      hour +
      ':' +
      minute +
      ':' +
      seconds

    return dateNow
  }

  useEffect(() => {
    if (arrayData) {
      console.log('ooo');
      if (dataMagazzino?.idBrand) {
        setIdBrandSelect(dataMagazzino?.idBrand)
      }
    }
    getTitle();
    selectBussiness();
    selectBrand();
    setTitle("Magazzino");
    reset(valueDefect);
    noEdit();
  }, []);

  useEffect(() => {
    if (dataMatricola) {
      let data: any = [];
      console.log('looo');

      for (let i = 0; i < dataMatricola.length; i++) {
        // data.push({idReferenzia:'', idNegozio:'', Matricola: dataMatricola[i].matricola ,  onFocus: false, hasError: false })
        data.push({
          idClient: dataMatricola[i].idClient,
          idMatricola: dataMatricola[i].idMatricola,
          idNegozio: dataMatricola[i].idMatricola,
          idReferenzia: dataMatricola[i].idReferenzia,
          Matricola: dataMatricola[i].matricola,
          putAside: dataMatricola[i].putAside,
          putMoveBusiness: dataMatricola[i].putMoveBusiness,
          putOnline: dataMatricola[i].putOnline,
          putSale: dataMatricola[i].putSale,
          putSaleExistingCustomer: dataMatricola[i].putSaleExistingCustomer,
          putSaleNewCustomer: dataMatricola[i].putSaleNewCustomer,
          onFocus: false,
          hasError: false,
        });
      }

      setListMatricole(data);
      setMatricole(data);
      setIsRedirect(true)
    }
  }, [dataMatricola]);

  useEffect(() => {
    if (route.params) {
       reset(valueDefect);
    }
  }, [route.params]);

  return (
    <div className="square">
      <ScrollView>
        {activeSpinner && <Loading activeSpinner={activeSpinner} />}
        <View style={stylesCercaMagazzino.header}>
          <Header
            hasRoundedIcon={false}
            title={title}
            onBack={() => navigation.navigate('MagazzinoCercaScreen', { send: 'addMagazzino', controlPanel:false })} ///CAMBIAR AQUI
          />
        </View>
        <div className="row" style={{ backgroundColor: "white" }}>
          <div className="col-md-10 col-sm-10 col-12 m-auto ml-5 pl-5">
            <View style={stylesCercaMagazzino.container}>
              {route.params ? (
                <Text style={stylesCercaMagazzino.label}>
                  Modifica prodotto 
                </Text>
              ) : (
                <Text style={stylesCercaMagazzino.label}>Carica prodotto</Text>
              )}

              <Text style={styles.label}>Referenza *</Text>
              <Controller
                control={control}
                name="Referencia"
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextInput
                    value={value}
                    onBlur={() => console.log("search client...")}
                    style={styleInput}
                    onChange={(e: any) => onChange(e?.target?.value)}
                    onChangeText={dataMagazzino ? null : (optionAddEdit === false) && onChangeTextRefenence}
                  />
                )}
                rules={{ required: true }}
              />
              {errors.Referencia?.type === "required" && (
                <Text style={stylesCercaMagazzino.labelError}>
                  {errorMessageRequired}
                </Text>
              )}
              {searchInputReference && autosuggestInputReference.length > 0 ? (
                <View
                  style={{
                    borderWidth: 1,
                    marginTop: -15,
                    paddingTop: 15,
                    marginLeft: 2,
                    marginRight: 2,
                    borderTopWidth: 0,
                    borderBottomLeftRadius: 20,
                    borderBottomRightRadius: 20,
                  }}
                >
                  <FlatList
                    data={autosuggestInputReference}
                    showsVerticalScrollIndicator={false}
                    renderItem={({ item, index }) => (
                      <Pressable
                        style={({ pressed }) => [
                          { opacity: pressed ? 0.5 : 1 },
                        ]}
                        onPress={() => {
                          setSearchInputReference(item.referencia),
                            setAutosuggestInputReference([]);
                          isActiveFilter = true;
                          resultSearch(item, true);
                        }}
                      >
                        {getItemText(item)}
                      </Pressable>
                    )}
                    keyExtractor={(item, index) => item.idReference + index}
                  />
                </View>
              ) : null}

              <div className="row">
                <div className="col-12 d-flex justify-content-end">
                  <TouchableOpacity>
                    <label
                      htmlFor="image-files-send"
                      style={{ cursor: "pointer" }}
                    >
                      <Image
                        style={[
                          styles.iconModal,
                          {
                            tintColor: "#b3b3b3",
                            marginTop: 10,
                          },
                        ]}
                        source={require("../../assets/icons/camera2.png")}
                      />
                    </label>
                    <input
                      type="file"
                      name="image-files-send"
                      id="image-files-send"
                      accept="image/png, image/gif, image/jpeg"
                      style={{ display: "none" }}
                      onChange={(e) => uploadFiles(e.target.files)}
                    />
                  </TouchableOpacity>
                </div>

                {arrayData.dataMagazzino && (
                  <div
                    className="col-12 d-flex justify-content-center"
                    id="img-container-2"
                  >
                    <div className="row">
                      {nameImgSelected.length == 0 && (
                        <div className="col-12 text-center">
                          {arrayData.dataMagazzino.fileName != null &&
                            imgUrl.length > 0 && (
                              <>
                                <img
                                  src={imgUrl}
                                  style={{ height: 120 }}
                                  className="img-fluid"
                                  alt=""
                                />
                                <br />
                                <br />
                                {(isAdmin || isMarketing) && (
                                  <a
                                    onClick={() => removeFileData()}
                                    style={{ cursor: "pointer" }}
                                    className="text-danger"
                                  >
                                    Rimuovere {arrayData.dataMagazzino.fileName}
                                  </a>
                                )}
                              </>
                            )}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <div className="col-12 d-flex justify-content-center">
                  <div className="row">
                    {nameImgSelected && imgUrl.length > 0 ? (
                      <div className="col-12 text-center">
                        <p>
                          <b>· Selezionato:</b> {nameImgSelected}
                        </p>
                      </div>
                    ) : null}
                    <div className="col-12 text-center">
                      <img
                        src={imgUrl}
                        className="d-none"
                        style={{ height: 120 }}
                        id="img-container-send"
                        alt=""
                      />
                    </div>
                    {nameImgSelected && imgUrl.length > 0 && (
                      <div className="col-12 text-center mt-2">
                        {(isAdmin || isMarketing) && (<a
                          onClick={() => removeFile()}
                          style={{ cursor: "pointer" }}
                          className="text-danger"
                        >
                          rimuovere {nameImgSelected}
                        </a>)}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div style={{ marginBottom: 35 }}></div>
              {listMatricole.map((element, index) => (
                <div key={index} className="col-6 mt-2">
                  <div className="row">
                    <div className="col-9">
                      <Text style={styles.label}>Matricola</Text>
                      <input
                        className="form-control"
                        value={element.Matricola}
                        onFocus={({ target }) =>
                          changeMatricola(
                            target.value,
                            "Matricola",
                            index,
                            target
                          )
                        }
                        onBlur={() => console.log("search client...")}
                        style={{
                          ...styleInput,
                          zIndex: 3,
                        }}
                        onChange={({ target }) =>
                          changeMatricola(
                            target.value,
                            "Matricola",
                            index,
                            target
                          )
                        }
                      />
                    </div>
                    <div className="col-3">
                      <div className="d-flex flex-row justify-content-between">
                        {listMatricole.length >= 0 && (
                          <TouchableOpacity
                            style={{
                              marginTop: 30,
                            }}
                          //onPress={() => removeMatricole(index)}
                          >
                            <label
                              //htmlFor={"online"}
                              style={{
                                fontSize: 18,
                                marginBottom: 5,
                                fontFamily: "Arsapia Regular font",
                              }}
                            >
                              <input
                                type="checkbox"
                                name="online"
                                className="checkDoble"
                                checked={element.putOnline}
                                style={{
                                  margin: 10,
                                  marginRight: 10,
                                  marginBottom: 10,
                                  fontSize: 100,
                                }}
                                id={"online"}
                                onChange={({ target }) =>
                                  changeMatricola(
                                    target.value,
                                    "putOnline",
                                    index,
                                    target
                                  )
                                }
                              />
                              Online
                            </label>
                          </TouchableOpacity>
                        )}
                        {listMatricole.length > 1 && (
                          <div
                            style={{
                              marginTop: 35,
                              marginLeft: 10,
                            }}
                          >
                            <TouchableOpacity
                              onPress={() => removeMatricole(index)}
                            >
                              <Image
                                style={{ width: 20, height: 20 }}
                                source={require("../../assets/icons/icon-delete.png")}
                              />
                            </TouchableOpacity>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* {element.onFocus && element.hasError && (
                                        <div>
                                            <Text style={stylesCercaMagazzino.labelError}>
                                                {errorMessageRequired}
                                            </Text>                    
                                        </div>
                                    )}  */}
                  <br />
                </div>
              ))}

              <TouchableOpacity onPress={() => addEnrollment()}>
                <div className="d-flex">
                  <Text
                    style={{
                      fontSize: 35,
                      fontWeight: "900",
                      marginTop: listMatricole.length > 1 ? 3 : 18,
                    }}
                  >
                    {" "}
                    +{" "}
                  </Text>
                  <Text
                    style={{
                      fontSize: 18,
                      marginTop: listMatricole.length > 1 ? 20 : 35,
                      fontFamily: "Arsapia Regular font",
                      color: "black",
                      marginBottom: 5,
                    }}
                  >
                    {" "}
                    Aggiungi matricola
                  </Text>
                </div>
              </TouchableOpacity>

              <Text style={styles.label}>Brand *</Text>
              <Controller
                control={control}
                name="Brand"
                render={({ field: { onChange, value, onBlur } }) => (
                  <select
                    className="mr-8 ml-8 "
                    style={
                      isAdmin || isMarketing
                        ? styleInput
                        : optionAddEdit || route.params
                          ? styleInputBlock
                          : styleInput
                    }
                    disabled={
                      isAdmin || isMarketing
                        ? false
                        : optionAddEdit || route.params
                          ? true
                          : false
                    }
                    value={value}
                    defaultValue={"default"}
                    onChange={(val) => { onChange(val.target.value), setIdBrandSelect(Number(val.target.value)) }}
                    aria-label=".form-select-lg example"
                  >
                    <option value="default">Seleziona</option>
                    {apiBrand.map((d: any) => (
                      <option key={Number(d.idBrand)} value={d.idBrand}>
                        {d.desc_Brand}
                      </option>
                    ))}
                  </select>
                )}
                rules={{ required: true }}
              />
              {errors.Brand?.type === "required" && (
                <Text style={stylesCercaMagazzino.labelError}>
                  {errorMessageRequired}
                </Text>
              )}
              <Text style={styles.label}>Descrizione</Text>
              <Controller
                control={control}
                name="Descrizione"
                render={({ field: { onChange, value, onBlur } }) => (
                  <select
                    className="mr-8 ml-8 "
                    style={
                      isAdmin || isMarketing
                        ? styleInput
                        : (optionAddEdit && dataMagazzino?.idFamiglia) || (route.params && dataMagazzino?.idFamiglia) || isModificateFamilia
                          ? styleInputBlock
                          : styleInput
                    }
                    disabled={
                      isAdmin || isMarketing
                        ? false
                        : (optionAddEdit && dataMagazzino?.idFamiglia) || (route.params && dataMagazzino?.idFamiglia) || isModificateFamilia
                          ? true
                          : false
                    }
                    value={value}
                    defaultValue={"default"}
                    onChange={(val) => onChange(val.target.value)}
                    aria-label=".form-select-lg example"
                  >
                    <option value="default">Seleziona</option>
                    {apiFamiglia.map((d: any) => (
                      <option key={Number(d.idFamiglia)} value={d.idFamiglia}>
                        {d.desc_famiglia}
                      </option>

                    ))}

                  </select>
                )}
                rules={{ required: false }}
              />
              {errors.Descrizione?.type === "required" && (
                <Text style={stylesCercaMagazzino.labelError}>
                  {errorMessageRequired}
                </Text>
              )}
              <Text style={styles.label}>Misura quadrante</Text>
              <Controller
                control={control}
                name="Misuraquadrante"
                render={({ field: { onChange, onBlur, value } }) => (
                  <input
                    className="form-control"
                    value={value}
                    style={styleInput}
                    onChange={(e) => onChange(e.target.value)}
                  />
                )}
              />
              <Text style={styles.label}>Colore quadrante</Text>

              <Controller
                control={control}
                name="Colorequadrante"
                render={({ field: { onChange, onBlur, value } }) => (
                  <input
                    className="form-control"
                    value={value}
                    style={styleInput}
                    onChange={(e) => onChange(e.target.value)}
                  />
                )}
              />
              <Text style={styles.label}>Tipo di movimento</Text>
              <Controller
                control={control}
                name="Tipodimovimento"
                render={({ field: { onChange, onBlur, value } }) => (
                  <input
                    className="form-control"
                    value={value}
                    style={styleInput}
                    onChange={(e) => onChange(e.target.value)}
                  />
                )}
              />
              <Text style={styles.label}>Negozio *</Text>
              <Controller
                control={control}
                name="Negozio"
                render={({ field: { onChange, value, onBlur } }) => (
                  <select
                    className="mr-8 ml-8 "
                    style={
                      isAdmin || isMarketing ? styleInput : styleInputBlock
                    }
                    value={
                      isAdmin || isMarketing
                        ? value
                        : dataMagazzino || optionAddEdit === true ? value : (value = userInLogin.idBussines)
                    }
                    disabled={isAdmin || isMarketing ? false : true}
                    defaultValue={(isAdmin || isMarketing) && "defaultNegozio"}
                    onChange={(val) => onChange(val.target.value)}
                    aria-label=".form-select-lg example"
                  >
                    <option value="defaultNegozio">Seleziona</option>
                    {apiBusiness.map((d: any) => (
                      <option key={Number(d.idBussines)} value={d.idBussines}>
                        {d.desc_Bussines}
                      </option>
                    ))}
                  </select>
                )}
                rules={{ required: true }}
              />
              {errors.Negozio?.type === "required" && (
                <Text style={stylesCercaMagazzino.labelError}>
                  {errorMessageRequired}
                </Text>
              )}

              <div className="col-6" style={{ marginTop: 33 }}>
                <Text style={styles.label}>Prezzo *</Text>
                <Controller
                  control={control}
                  name="Prezzo"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <CurrencyInput
                      className="form-control"
                      value={value ? Number(value).toFixed(2) : 0}
                      disabled={
                        (isAdmin || isMarketing || isDipendente || isDirectore)
                          ? false
                          : optionAddEdit || route.params
                            ? true
                            : false
                      }
                      style={
                        (isAdmin || isMarketing || isDipendente || isDirectore)
                          ? styleInput
                          : optionAddEdit || route.params
                            ? styleInputBlock
                            : styleInput
                      }
                      intlConfig={{ locale: "it-IT" }}
                      decimalsLimit={2}
                      onValueChange={(valueString: any) => {
                        onChange(
                          valueString ? valueString.replace(",", ".") : ""
                        );
                      }}
                    // onChange={(e) => onChange(e.target.value)}
                    />
                  )}

                  rules={{ required: true }}
                />
                <div style={{ marginBottom: 18 }}></div>
                {errors.Prezzo?.type === "required" && (
                  <Text style={stylesCercaMagazzino.labelError}>
                    {errorMessageRequired}
                  </Text>
                )}
              </div>

              <div
                className="col-12 d-flex justify-content-end"
                style={{ marginTop: 33, textAlign: "end", color: "white" }}
              >
                {route.params && optionAddEdit == false ? (
                  <TouchableOpacity
                    onPress={handleSubmit(() => {
                      handleDataEdit(true);
                    })}
                    style={stylesCercaMagazzino.button}
                  >
                    Salva Modifiche
                  </TouchableOpacity>
                ) : (
                  <TouchableOpacity
                    onPress={
                      optionAddEdit === false
                        ? // ? handleSubmit(() => {activeFocus(); handleData(true)} )
                        handleSubmit(() => {
                          handleData(true);
                        })
                        : handleSubmit(() => {
                          handleDataEdit(true);
                        })
                    }
                    style={stylesCercaMagazzino.button}
                  >
                    {optionAddEdit === true ? "Salva Modifiche" : "Salva"}
                  </TouchableOpacity>
                )}
              </div>
            </View>
          </div>
        </div>

        {modaleMessaggeWithUrl.active && (
          <PopUpMessageWithRoute
            routeNav={modaleMessaggeWithUrl.route}
            dataNav={[]}
            title={modaleMessaggeWithUrl.title}
            message={modaleMessaggeWithUrl.message}
            stateModal={modaleMessaggeWithUrl.active}
            setStateModal={setmodaleMessaggeWithUrl}
            confirmAction={confirmAction}
          />
        )}
      </ScrollView>
    </div>
  );
};

const styleInputBase = {
  width: "100%",
  height: 50,
  margin: 0,
  borderWidth: 2,
  borderRadius: 50,
  borderColor: "black",
  paddingLeft: 20,
  fontFamily: "Arsapia Regular font",
};

const styleInput = {
  ...styleInputBase,
  backgroundColor: "transparent",
};

const stylesCercaMagazzino = StyleSheet.create({
  header: {
    paddingHorizontal: 12,
    backgroundColor: "white",
  },
  container: {
    flex: 1,
    backgroundColor: "white",
    paddingHorizontal: 47,
    paddingBottom: 40,
    alignContent: "center",
    justifyContent: "center",
  },
  label: {
    alignSelf: "flex-start",
    marginTop: 25,
    marginBottom: 10,
    fontWeight: "500",
    fontSize: 20,
  },
  labelAdd: {
    fontSize: 35,
    fontWeight: "900",
    marginTop: 18,
  },
  labelFilter: {
    alignSelf: "flex-start",
    marginTop: 25,
    marginBottom: 10,
    fontSize: 18,
    fontWeight: "500",
    fontFamily: "Arsapia Regular font",
  },
  labelError: {
    fontSize: 18,
    marginLeft: 35,
    marginTop: 15,
    fontFamily: "Arsapia Regular font",
    color: "red",
  },
  input: {
    height: 45,
    width: "100%",
    borderWidth: 2,
    fontFamily: "Arsapia Regular font",
    padding: 6,
    borderRadius: 50,
    marginRight: "1%",
  },
  arrowDown: {
    width: 20,
    height: 20,
    marginTop: 18,
    tintColor: "#808080",
    alignSelf: "center",
  },
  viewDisable: {
    backgroundColor: "black",
    borderRadius: 50,
    justifyContent: "center",
    width: 40,
    height: 40,
    marginTop: "2%",
  },
  search: {
    marginLeft: "-100%",
  },
  button: {
    width: 200,
    height: 50,
    alignSelf: "center",
    backgroundColor: "black",
    borderRadius: 50,
    alignItems: "center",
    justifyContent: "center",
  },
});