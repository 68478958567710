import React, { useEffect, useState, useContext } from "react";
import { ScrollView, StyleSheet, TouchableOpacity, View } from "react-native";
import Loading from "../../components/Loading";
import { HeaderNewRepair as Header } from "../../components/HeaderNewRepair";
import { StackScreenProps } from "@react-navigation/stack";
import { styles } from "../../theme/GlobalStyle";
import { Image, Text } from "react-native-elements";
import { todayFormatDate } from "../../utils/helpers";
import ConfigCapone from "../../utils/ConfigCapone";
import axios from "axios";
import * as XLSX from "xlsx";
import { Button } from 'react-native'
import ReactExport from "react-data-export";
import PopUpMessageWithRoute from "../../components/PopUpMessageWithRoute";
import PopUpMessageWithRouteLog from "../../components/PopUpMessageWithRouteLog";
import { useNotifyCrud } from "../../hooks/useNotifyCrud";
import { UserContext } from "../../context/UserContext";
import { ResponseNotify } from "../../interfaces/Notify";
import { getAllNotify } from "../../services/Notify";
import Cookies from 'universal-cookie';
import DatePicker, {registerLocale} from "react-datepicker";
import { useForm, Controller } from "react-hook-form";
import { useModal } from "../../hooks/useModal";
import { ModalAplication } from "../../components/Modal/ModalAplication";
import it from "date-fns/locale/it"
registerLocale("it",it)
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
interface Props extends StackScreenProps<any, any> {}

export const ImportExportScreen = ({ route, navigation }: Props) => {
  const [activeSpinner, setActiveSpinner] = useState(false);
  const [activePopPup, setActivePopPup] = useState(false);
  const [title, setTitle] = useState("");
  const { createdPosted } = useNotifyCrud();

//MOFICIQUI IDUSER
const { userInLogin } = useContext(UserContext);
const [dateF, setDateF] = useState()
  const [open, setOpen] = useState(false)
  const [notifiche, setNotifiche] = useState<ResponseNotify[]>([]);
//cookie
const cookies = new Cookies();
const { closeModal, isOpen, openModalAdd } = useModal();
const [fecha, setfecha] = useState<any>();
//ModalConfirm
const [modalData, setModalData] = useState(false);
  // Export
  const [loadingData,setLoadingData] = useState<boolean>(false);
  const [nameFileExport, setNameFileExport] = useState<any>(
    "Tabella Referenza " + todayFormatDate
  );
  const [tableCsv, setTableCsv] = useState<any>([]);
  const [tableFilterCsv, setTableFilterCsv] = useState<any>([]);
  const [tableLogg, setTableLogg] = useState<any>([]);
  const [modaleMessaggeWithUrl, setmodaleMessaggeWithUrl] = useState({
    title: "Informazione",
    message: "",
    active: false,
    route: "",
  });
  const [modaleMessaggeWithUrlLog, setmodaleMessaggeWithUrlLog] = useState({
    title: "Informazione",
    message: "",
    active: false,
    route: "",
    fecha:"",
  });
  const valueDefect:any = {     
    fecha:"" ,
    }
  const {
    setValue,
    getValues,
    handleSubmit,
    control,
    reset,
    formState: { errors },
} = useForm({
    defaultValues: valueDefect,
});
  const handleDataExport = async () =>{
    if(DataSet[0].data===0){
        setModalData(true)
        return
    }
    setLoadingData(true)
    await dataExportMatricola()
    setLoadingData(false)
}
const sendLogg = async (mesaggeN:string) => {
  let selectedMessage = {
    message: mesaggeN,
};
  await axios
  .post(`${ConfigCapone.urlapi}/api/Notify/Logg/`,selectedMessage,{
      headers: {
        'Authorization': `Bearer ${cookies.get('token')}`
      }
    })
      .then((response) => {
        console.log(response)
      })
      .catch((error) => console.log(error));
};
  const dataExportMatricola = async () => {
     //NEW ENDPOINT EXCEL
     let selectedReferencia = {
      listidReference: [],
  };
  
  setTableFilterCsv([]);
  for(let i=0; i<tableCsv.length; i++){
      selectedReferencia.listidReference.push(tableCsv[i].idReference)
  }
  console.log("aquiREF",selectedReferencia)
  await axios
     .post(`${ConfigCapone.urlapi}/api/Referenzia/ReferenziaEsportaDatabase`, selectedReferencia,{
      headers: {
        'Authorization': `Bearer ${cookies.get('token')}`
      }
    })
     .then((response) => {
      setTableFilterCsv(response.data.response)
     })
      .catch((err) => {
         console.log(err)

      })
   // let list: any = [];
    //setTableFilterCsv([]);
   // for (let index = 0; index < tableCsv.length; index++) {
   //   let matricole: any = [];
   //   let matricoleList: any = [];
   //   await axios
   //     .get(
   //       `${ConfigCapone.urlapi}/api/Matricola/FindByReference/${tableCsv[index].idReference}`
   //     )
   //     .then((response) => {
   //       matricole = response.data.response;
   //     })
    //    .catch((err) => console.log(err));

    //  for (let i = 0; i < matricole.length; i++) {
   //     matricoleList.push(matricole[i].matricola);
    //  }

   //   list.push({
   //     referencia: tableCsv[index].referencia,
    //    desc_Brand: tableCsv[index].desc_Brand,
    //    desc_Bussines: tableCsv[index].desc_Bussines,
    //    prezzo: tableCsv[index].prezzo,
    //    matricola: matricoleList.toString(),
     //   fileName: tableCsv[index].fileName,
   //   });
  //  }
  //  console.log(list)

   // setTableFilterCsv(list);
  };
  function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}
function formatDateName(date) {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [day, month, year].join('-');
}
const exportLoggAll =async ()=>{
  closeModal()
  console.log(dateF)
  dataExportLogg()
}
  const dataExportLogg = async () => {
    let fechaLog:any;
    let nameExport:any;
    if(dateF!=undefined){
      fechaLog =formatDate(dateF);
      nameExport =formatDateName(dateF);  
    }else{
      fechaLog  = formatDate(new Date().toDateString());
      nameExport  = formatDateName(new Date().toDateString());
    }
    
    //console.log(fechaNow)
 setTableLogg([]);
 await axios
    .get(`${ConfigCapone.urlapi}/api/Notify/ListLog?fecha=${fechaLog}`,{
     headers: {
       'Authorization': `Bearer ${cookies.get('token')}`
     }
   })
    .then((response) => {
    if(response.data != "File Not Found"){
      const a = document.createElement("a");
      const archivo = new Blob([response.data.join('')], { type: 'text/plain' });
      const url = URL.createObjectURL(archivo);
      a.href = url;
      a.download = nameExport;
      a.click();
      URL.revokeObjectURL(url);
    }else{
      showConfirmLogPopup()
    }
    
      //setTableLogg(response.data.response)
    })
     .catch((err) => {
        console.log(err)

     })
    };
  const DataSet = [
    {
      columns: [
        { title: "Referenza*", style: { font: { sz: "18", bold: true } } },
        { title: "Brand*", style: { font: { sz: "18", bold: true } } },
        { title: "Matricola", style: { font: { sz: "18", bold: true } } },
        { title: "Negozio", style: { font: { sz: "18", bold: true } } },
        { title: "Prezzo*", style: { font: { sz: "18", bold: true } } },
        { title: "Foto", style: { font: { sz: "18", bold: true } } },
      ],
      data:
        tableFilterCsv &&
        tableFilterCsv.map((data: any) => [
          {
            value: data.referenza ? data.referenza : "",
            style: { font: { sz: "14", bold: false } },
          },
          {
            value: data.brand ? data.brand : "",
            style: { font: { sz: "14", bold: false } },
          },
          {
            value: data.matricolas ? data.matricolas : "",
            style: { font: { sz: "14", bold: false } },
          },
          {
            value: data.negozio ? data.negozio : "",
            style: { font: { sz: "14", bold: false } },
          },
          {
            value: data.prezzo ? data.prezzo : "",
            style: { font: { sz: "14", bold: false } },
          },
          {
            value: data.foto ? data.foto : "",
            style: { font: { sz: "14", bold: false } },
          },
        ]),
    },
  ];
  const dataNotify = [
    {
      columns: [
        { title: "coduser", style: { font: { sz: "14", bold: true } } },
        { title: "Nome", style: { font: { sz: "14", bold: true } } },
        { title: "Cognome", style: { font: { sz: "14", bold: true } } },
        { title: "Azione", style: { font: { sz: "14", bold: true } } },
        { title: "Messaggio", style: { font: { sz: "14", bold: true } } },
        { title: "Data", style: { font: { sz: "14", bold: true } } },
      ],
      data:
        notifiche &&
        notifiche.map((data) => [
          {
            value: data.idUser || "",
            style: { font: { sz: "14", bold: false } },
          },
          {
            value: data.name || "",
            style: { font: { sz: "14", bold: false } },
          },
          {
            value: data.lastName || "",
            style: { font: { sz: "14", bold: false } },
          },
          {
            value: data.actionLog || "",
            style: { font: { sz: "14", bold: false } },
          },
          {
            value: data.message || "",
            style: { font: { sz: "14", bold: false } },
          },
          {
            value: data.dateExport || "",
            style: { font: { sz: "14", bold: false } },
          },
        ]),
    },
  ];
  const getDataReference = async () => {
    setActiveSpinner(true);
    await axios
      .get(`${ConfigCapone.urlapi}/api/Referenzia/FindExport`)
      .then((response) => {
        setTableCsv(response.data.response);
      })
      .catch((err) => console.log(err));
    setActiveSpinner(false);
  };
  const downloadLogg =async () =>{
    const a = document.createElement("a");
    const archivo = new Blob([tableLogg], { type: 'text/plain' });
    const url = URL.createObjectURL(archivo);
    a.href = url;
    a.download = "Log";
    a.click();
    URL.revokeObjectURL(url);

  }
  const uploadImages = async () => {
    let element = document.getElementById("upload-multiple-images");
    element.click();
  };

  const uploadFileExcel = async () => {
    let element = document.getElementById("upload-file-excel");
    element.click();
  };

  const uploadListino = async () => {
    let element = document.getElementById("upload-file-listino");
    element.click();
  };
  const uploadFamiglia = async () => {
    let element = document.getElementById("upload-file-famiglia");
    element.click();
  };

  const handleUploadFile = async () => {
    setActiveSpinner(true);
    let image: any = document.querySelector("#upload-multiple-images");
    let myNewFile: any = null;

    if (image.files.length > 0) {
      for (let i = 0; i < image.files.length; i++) {
        let extension: string = image.files[i].name.substr(
          image.files[i].name.lastIndexOf(".") + 1
        );

        let formData = new FormData();

        myNewFile = new File([image.files[i]], image.files[i].name, {
          type: image.files[i].type,
        });
        
        formData.append("files", myNewFile);
        formData.append("type", "image");
        formData.append("name", image.files[i].name);
        formData.append("extension", extension);
        sendLogg(` ha importato ${image.files[i].name} il carica foto `)
        await axios
          .post(`${ConfigCapone.apicapone}/upload/prodotto`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {});
          console.log("eee",formData)
        }
      
      setActiveSpinner(false);

      showSuccessPopup();
    }
  };
  function isNumeric(val) {
    return /^-?\d+$/.test(val);
  }
  const modalShow = async ()=>{
    setActivePopPup(false);
    showSuccessPopup() 
  }
  const handleUploadListinoPopUP = async () =>{
    //let stateModal: boolean = false;
    setActivePopPup(true);
    await handleUploadListino();
    setActivePopPup(false);
    modalShow();
     //      
}
const handleUploadFamigliaPopUP = async () =>{
  //let stateModal: boolean = false;
  setActivePopPup(true);
  await handleUploadFamiglia();
  setActivePopPup(false);
  modalShow();
   //      
}
  const handleUploadListino = async () => {
    console.log("upload listino...");
    let file: any = document.getElementById("upload-file-listino");
    console.log(file.files[0]);

    
    
    if (file.files[0] != undefined) {
      

      const data = await file.files[0].arrayBuffer();
      const workbook = XLSX.read(data);

      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData: any = XLSX.utils.sheet_to_json(worksheet);

      const cleanedData = jsonData.map((item: any) => {
        const cleanedItem: any = {};
        Object.entries(item).forEach(([key, value]) => {
          const trimmedKey = key.trim(); 
          cleanedItem[trimmedKey] = value;
        });
        return cleanedItem;
      });

      console.log('qq',cleanedData);

      let extension: string = file.files[0].name.substr(
        file.files[0].name.lastIndexOf(".") + 1
      );

      let fileName = getFileName() + "." + extension;

      await uploadFile(file, "listino", fileName, extension);

      await axios
        .post(`${ConfigCapone.urlapi}/api/UploadFiles/UploadListino`, cleanedData,{
          headers: {
            'Authorization': `Bearer ${cookies.get('token')}`
          }
        })
        .then((response) => {
         console.log(response)
        })
        .catch((error) => {
          console.log(error)
        });


        
      file.value = null;
      
      
      
    }
  };
  const handleUploadFamiglia = async () => {
    console.log("upload famiglia...");
    let file: any = document.getElementById("upload-file-famiglia");
    console.log(file.files[0]);
    if (file.files[0] != undefined) {
      const data = await file.files[0].arrayBuffer();
      const workbook = XLSX.read(data);

      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData: any = XLSX.utils.sheet_to_json(worksheet);

      const cleanedData = jsonData.map((item: any) => {
        const cleanedItem: any = {};
        Object.entries(item).forEach(([key, value]) => {
          const trimmedKey = key.trim(); 
          cleanedItem[trimmedKey] = value;
        });
        return cleanedItem;
      });

      console.log('qq',cleanedData);

      let extension: string = file.files[0].name.substr(
        file.files[0].name.lastIndexOf(".") + 1
      );

      let fileName = getFileName() + "." + extension;

      await uploadFile(file, "famiglia", fileName, extension);

      await axios
        .post(`${ConfigCapone.urlapi}/api/UploadFiles/UploadFamiglia`, cleanedData,{
          headers: {
            'Authorization': `Bearer ${cookies.get('token')}`
          }
        })
        .then((response) => {
         console.log(response)
        })
        .catch((error) => {
          console.log(error)
        });


        
      file.value = null;
      
      
      
    }
  };
  const handleUploadFileExcel = async () => {
    let file: any = document.getElementById("upload-file-excel");
    console.log(file.files[0]);

    if (file.files[0] != undefined) {
      setActiveSpinner(true);

      // const data = await file.files[0].arrayBuffer();
      // const workbook = XLSX.read(data);

      // const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      // const jsonData:any = XLSX.utils.sheet_to_json(worksheet);

      let extension: string = file.files[0].name.substr(
        file.files[0].name.lastIndexOf(".") + 1
      );

      let fileName = getFileName() + "." + extension;

      await uploadFile(file, "referenza", fileName, extension);

      let data = {
        file: fileName,
        idUs: userInLogin.idUser,
      };

      await axios
        .post(`${ConfigCapone.urlapi}/api/UploadFiles`, data,{
          headers: {
            'Authorization': `Bearer ${cookies.get('token')}`
          }
        })
        .then((response) => {
          setActiveSpinner(false);
          showSuccessPopup();
        })
        .catch((error) => {
          setActiveSpinner(false);
          showSuccessPopup();
        });

      file.value = null;
    }
  };

  const uploadFile = async (
    file: any,
    type: string,
    name: string,
    extension: string
  ) => {
    let myNewFile: any = null;
    let formData = new FormData();

    myNewFile = new File([file.files[0]], name, {
      type: file.files[0].type,
    });
    console.log("file name: " + name);
    formData.append("files", myNewFile);
    formData.append("type", "image");
    formData.append("name", name);
    formData.append("extension", extension);
    await axios
      .post(`${ConfigCapone.apicapone}/upload/` + type, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {});
  };

  const getFileName = () => {
    let date = new Date().toLocaleDateString();
    date = date.replaceAll("/", "_");
    let hour = new Date().toLocaleTimeString();
    hour = hour.replaceAll(":", "_");
    date += "_" + hour;
    date = date.replace(/\s/g, "");

    console.log("dd:hh -> " + date);
    return date;
  };

  const showSuccessPopup = () => {
    setmodaleMessaggeWithUrl({
      active: true,
      message: "Caricamento avvenuto con successo",
      title: "Informazione",
      route: "",
    })
  };
  const showConfirmLogPopup = () => {
    setmodaleMessaggeWithUrlLog({
      active: true,
      message: "Nessun record trovato per quella data",
      title: "Errore.",
      route: "",
      fecha:"",
    })
  };

  const confirmAction = (Landing: any, data: any) => {
    navigation.navigate(Landing, data);
    setmodaleMessaggeWithUrl({
      title: "Informazione",
      message: "",
      active: false,
      route: "",
    });
    
  };
  const confirmActionLog = (Landing: any, data: any) => {
    navigation.navigate(Landing, data);
    setmodaleMessaggeWithUrlLog({
      title: "Informazione",
      message: "",
      active: false,
      route: "",
      fecha:modaleMessaggeWithUrlLog.fecha,
    });
  };

  const handleNotify = async () => {
    try {
      const resp = await getAllNotify(2);
      setNotifiche(resp);
    } catch (error) {}
  };

 // useEffect(() => {
  //  if (Object.keys(tableCsv).length > 0) {
   //   dataExportMatricola();
  //  }
  //}, [tableCsv]);

  useEffect(() => {
    setTitle("Importazione di massa");
    getDataReference();
  }, []);
  useEffect(() => {
    handleNotify();
    
    //downloadLogg();
  }, []);
  //if (loadingData ) return <Loading activeSpinner={loadingData} />;

  return (
    <div className="square">
      <ScrollView>
      {loadingData && <Loading activeSpinner={loadingData} />}
        {activeSpinner && <Loading activeSpinner={activeSpinner} />}
        {activePopPup && <Loading activeSpinner={activePopPup} />}
        <View style={stylesImportExport.header}>
          <Header
            hasRoundedIcon={false}
            title={title}
            onBack={() => navigation.navigate("HomeSCDueScreen", {  refresh  : true })}
          />
        </View>
        <>        
          </>
        <div className="row" style={{ backgroundColor: "white" }}>
          <div className="col-md-10 col-sm-10 col-12 m-auto ml-5 pl-5">
            <View style={stylesImportExport.container}>
              <div className="col-12" style={{ marginLeft: 40 }}>
                <TouchableOpacity onPress={() => uploadFileExcel()}>
                  <div className="d-flex justify-content-start">
                    <Image
                      source={require("../../assets/icons/link.png")}
                      style={stylesImportExport.image}
                    />
                    <Text
                      style={[styles.label, { fontSize: 20, marginLeft: 30 }]}
                    >
                      Carica file Excel
                    </Text>
                  </div>
                  <input
                    style={{ display: "none" }}
                    onChange={() => handleUploadFileExcel()}
                    accept=".xlsx, .xls"
                    type="file"
                    id="upload-file-excel"
                  />
                </TouchableOpacity>
              </div>

              <div className="col-12" style={{ marginLeft: 40, marginTop: 10 }}>
                <TouchableOpacity onPress={() => uploadImages()}>
                  <div className="d-flex justify-content-start">
                    <Image
                      source={require("../../assets/icons/link.png")}
                      style={stylesImportExport.image}
                    />
                    <Text
                      style={[styles.label, { fontSize: 20, marginLeft: 30 }]}
                    >
                      Carica foto
                    </Text>
                  </div>
                  <input
                    style={{ display: "none" }}
                    onChange={() => handleUploadFile()}
                    accept="image/png, image/jpeg"
                    type="file"
                    id="upload-multiple-images"
                    multiple
                  />
                </TouchableOpacity>
              </div>

              <div className="col-12" style={{ marginLeft: 40, marginTop: 10 }}>
                <TouchableOpacity onPress={() => uploadListino()}>
                  <div className="d-flex justify-content-start">
                    <Image
                      source={require("../../assets/icons/link.png")}
                      style={stylesImportExport.image}
                    />
                    <Text
                      style={[styles.label, { fontSize: 20, marginLeft: 30 }]}
                    >
                      Carica listino
                    </Text>
                  </div>
                </TouchableOpacity>
                <input
                  style={{ display: "none" }}
                  onChange={() => handleUploadListinoPopUP()}
                  accept=".xlsx, .xls"
                  type="file"
                  id="upload-file-listino"
                />
              </div>
              <div className="col-12" style={{ marginLeft: 40, marginTop: 10 }}>
                <TouchableOpacity onPress={() => uploadFamiglia()}>
                  <div className="d-flex justify-content-start">
                    <Image
                      source={require("../../assets/icons/link.png")}
                      style={stylesImportExport.image}
                    />
                    <Text
                      style={[styles.label, { fontSize: 20, marginLeft: 30 }]}
                    >
                      Carica famiglia
                    </Text>
                  </div>
                </TouchableOpacity>
                <input
                  style={{ display: "none" }}
                  onChange={() => handleUploadFamigliaPopUP()}
                  accept=".xlsx, .xls"
                  type="file"
                  id="upload-file-famiglia"
                />
              </div>
              <div
                className="col-12 d-flex justify-content-start"
                style={{ marginLeft: 40, marginTop: 10 }}
              >
                <Image
                  source={require("../../assets/icons/icon-download.png")}
                  style={stylesImportExport.image}
                />
                {(!loadingData && DataSet[0].data.length>0) && <ExcelFile
                  filename={nameFileExport}
                  hideElement
                >
                  <ExcelSheet dataSet={DataSet} name="Lista referenza" />
                </ExcelFile>}
                <button
                      onClick={() =>{handleDataExport();
                        createdPosted({
                          actionLog: "Esportare",
                          message: "Esportare il database dell'applicazione",
                        })
                      }}
                      type="button"
                      className="dowloandExport"
                    >
                      <Text
                        style={[styles.label, { fontSize: 20, marginLeft: 2 }]}
                      >
                        Esporta database
                      </Text>
                    </button>
              </div>
              <div
                className="col-12 d-flex justify-content-start"
                style={{ marginLeft: 40, marginTop: 10 }}
              >
                <Image
                  source={require("../../assets/icons/icon-download.png")}
                  style={stylesImportExport.image}
                />
                {/* <ExcelFile
                  filename={new Date().toDateString()}
                  element={
                   
                  }
                >
                  
                </ExcelFile> */}
                <button type="button" className="dowloandExport" onClick={()=>openModalAdd()}>
                      <Text
                        style={[styles.label, { fontSize: 20, marginLeft: 2 }]}
                      >
                        Esporta notifiche
                      </Text>
                    </button>
              </div>
            </View>
          </div>
        </div>

        {modaleMessaggeWithUrl.active && (
          <PopUpMessageWithRoute
            routeNav={modaleMessaggeWithUrl.route}
            dataNav={[]}
            title={modaleMessaggeWithUrl.title}
            message={ modaleMessaggeWithUrl.message}
            stateModal={modaleMessaggeWithUrl.active}
            setStateModal={setmodaleMessaggeWithUrl}
            confirmAction={confirmAction}
          />
        )}
        {isOpen && (
        <ModalAplication
          title="Data di ricerca"
          isOpen={isOpen}
          closeModal={closeModal}
        >
          <label >Seleziona la data</label>
          <div className="mb-4">
          <Controller
                            control={control}
                            name="DateEsporta"
                            render={({ field: { onChange, onBlur, value } }) => (
                                <DatePicker
                                    locale={it}
                                    dateFormat='dd/MM/yyyy'
                                    style={{paddingLeft:100}}
                                    maxDate={new Date()}
                                    onChange={(date) => {setDateF(date),onChange(date)}}
                                    selected={dateF?value:new Date()}
                                    showYearDropdown
                                    scrollableMonthYearDropdown
                                />                   
                            )}
                        />
            </div>  
            <div className="modal-footer"><button className="btn btn-success" onClick={()=> exportLoggAll()} >Esportare</button></div>
        </ModalAplication>
        )}

      
        {modaleMessaggeWithUrlLog.active && (
          <PopUpMessageWithRouteLog
          routeNav={modaleMessaggeWithUrlLog.route}
          dataNav={[]}
          title={modaleMessaggeWithUrlLog.title}
          message={modaleMessaggeWithUrlLog.message}
          stateModal={modaleMessaggeWithUrlLog.active}
          setStateModal={setmodaleMessaggeWithUrlLog}
          confirmAction={confirmActionLog}
          />
        )}

      </ScrollView>
    </div>
  );
};

const stylesImportExport = StyleSheet.create({
  header: {
    paddingHorizontal: 12,
    backgroundColor: "white",
  },
  container: {
    flex: 1,
    backgroundColor: "white",
    paddingHorizontal: 47,
    paddingBottom: 40,
    alignContent: "center",
    justifyContent: "center",
  },
  image: {
    width: 35,
    height: 35,
    marginTop: 30,
    tintColor: "#808080",
    alignSelf: "center",
  },
});
